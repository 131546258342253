export default {
    props: {
        disabled: { type: Boolean, default: false },
        type: { type: String, default: null },
        value: { type: String|Number, default: null },
        pattern: { type: String, default: null },
        maxlength: { type: Number, default: null },
        minlength: { type: Number, default: null },
        readonly: { type: Boolean, default: null },
        autocomplete: { type: String, default: null },
        title: { type: String, default: null },
        placeholder: { type: String|Boolean, default: null },
    },
    data() {
        return {
            fieldType: this.getFieldValue('type', 'type', 'text'),
            fieldValue: this.getFieldValue('value', 'value', ''),
            fieldPattern: this.getFieldValue('pattern', 'pattern', null),
            fieldMaxlength: this.getFieldValue('maxlength', 'attr.maxlength', null),
            fieldMinlength: this.getFieldValue('minlength', 'attr.minlength', null),
            fieldReadonly: this.getFieldValue('readonly', 'attr.readOnly', false),
            fieldPlaceholder: this.getFieldValue('placeholder', 'placeholder', false),
            fieldAutoComplete: this.getFieldValue('autocomplete', 'autocomplete', false),
            fieldTitle: this.getFieldValue('title', 'title', false),
        };
    },
    watch: {
        type(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldType = newValue;
            }
        },
        value(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldValue = newValue;
            }
        },
        pattern(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldPattern = newValue;
            }
        },
        maxlength(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldMaxlength = newValue;
            }
        },
        minlength(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldMinlength = newValue;
            }
        },
        readonly(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldReadonly = newValue;
            }
        },
        placeholder(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldPlaceholder = newValue;
            }
        },
        autocomplete(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldAutoComplete = newValue;
            }
        },
        title(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldTitle = newValue;
            }
        },
    },
};
