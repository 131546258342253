var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "marketingHubPage__quickStart outlookMarketingHub__icons panel__opened",
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "marketingHubPage--quickStart__header text-color--dark-blue-gray hideMobile",
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.trans("XMO_MARKET_OUTLOOKS_QUICKSTART")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.quickStartItems, function (item, index) {
          return _c(
            "a",
            {
              key: `${item.shortTitle}--${index}`,
              staticClass: "flex flex--center flex--nowrap quickStart__item",
              attrs: {
                href: `${_vm.basePath}${item.path}`,
                "data-gtm": `xmoMarketingPage__quickstart--${item.shortTitle}`,
              },
              on: {
                mousemove: function ($event) {
                  return _vm.setShortTitle(item.shortTitle)
                },
              },
            },
            [
              _c("div", { staticClass: "quickStart__svg" }, [
                _c(
                  "svg",
                  {
                    class: _vm.generateIconClass(item.shortTitle),
                    attrs: {
                      viewBox: "0 0 32 32",
                      width: "32",
                      height: "32",
                      xmlns: "http://www.w3.org/2000/svg",
                      "aria-hidden": "true",
                    },
                  },
                  [
                    _c("circle", {
                      staticClass: "outlookClusterIcon__backgroundCircle",
                      attrs: { cx: "16", cy: "16", r: "16" },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "outlookClusterIcon__iconPath",
                      attrs: {
                        d: _vm.icons[item.shortTitle].path,
                        fill: "none",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "margin-bottom-10 margin-bottom-s-5 padding-right-20",
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "text-color--dark-blue-gray margin-top-10 margin-bottom-5 marketingHubPage__resultsHeader",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.trans(item.name)) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.teaser) +
                        "\n                "
                    ),
                  ]),
                ]
              ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }