var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "xmoMarketingHubPage--markets flex flex--center",
      class: {
        "flex--justifyContent-center": _vm.isHighlightsHeader && !_vm.isMobile,
      },
    },
    [
      _vm.popularMarketsTitle.length
        ? _c("p", { staticClass: "margin-right-10" }, [
            _vm._v("\n        " + _vm._s(_vm.popularMarketsTitle) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPopularMarkets ? [_vm._t("default")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }