<template>
    <div class="panel__opened">
        <div @mousemove="setCurrentHovered">
            <ul class="outlookSearchResults scrollBox pos-static outlookSearchResults--small">
                <li>
                    <h2 class="marketingHubPage--quickStart__header text-color--dark-blue-gray">
                        {{ searchResultsHeadline }}
                        <span
                            v-if="showAiResults"
                            class="xmoSearchAiRecommendation__pill xmoSearchAiRecommendation__pill--large">
                            {{ trans('GLOBAL_BETA') }}
                        </span>
                    </h2>
                </li>
                <li
                    v-for="(match, index) in matches"
                    :key="`${ match.product }--${ index }`"
                    ref="results"
                    class="marketingHubPageAutoComplete--resultsItem outlookMarketingHub__icons flex flex-center quickStart__item"
                    @keyup.tab="changeProduct(match)"
                    @mousemove="setTabActive(match, $refs.results, $event)">
                    <!-- Left Section -->
                    <div class="width50 marketingHubPage__results--products cursor-pointer">
                        <a
                            class="marketingLinks results--product focus-inset flex flex-center"
                            :href="generateMarketLink(match.url, match.slug)"
                            :data-gtm="`xmoMarketingPage__searchResult--${ sanitizeDataGtm(match) }`">
                            <!-- Market SVG -->
                            <div class="margin-left-20">
                                <svg
                                    :class="generateIconClass(match)"
                                    viewBox="0 0 32 32"
                                    width="32"
                                    height="32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true">
                                    <circle
                                        class="outlookClusterIcon__backgroundCircle"
                                        cx="16"
                                        cy="16"
                                        r="16" />
                                    <path
                                        class="outlookClusterIcon__iconPath"
                                        :d="getSvgIcon(match.product)"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div class="flex flex--nowrap padding-right-20">
                                <div>
                                    <h2 class="marketingHubPage__results--market margin-bottom-5 text-color--dark-blue-gray">
                                        {{ generateSearchResultName(match) }}
                                    </h2>
                                    <small class="resultsItem--product__small marketingHubPage__resultsHeader--topic">
                                        {{ generateMarketName(match.product) }}
                                    </small>
                                </div>
                            </div>
                        </a>
                    </div>
                    <!-- Right Section -->
                    <div
                        v-show="selectedProduct.uid === match.uid && !showAiResults"
                        class="width50 padding-right-20 outlookOverview scrollBox max-height-550 hideMobile">
                        <h3 class="hl-module text-color--primary-contrast margin-top-15 margin-bottom-10 margin-left-20">
                            {{ generateMarketName(selectedProduct.product) }}
                        </h3>
                        <h4 class="h3 margin-bottom-2 margin-left-20">
                            {{ selectedProduct.market }}
                        </h4>
                        <div class="margin-bottom-15 margin-left-20">
                            <p class="margin-bottom-2">
                                {{ formatDescription(selectedProduct.description) }}
                            </p>
                        </div>
                        <div class="margin-bottom-15 margin-left-20">
                            <a
                                :href="generateMarketLink(selectedProduct.url, selectedProduct.slug)"
                                class="linkArrowHover margin-top-20"
                                :data-gtm="`xmoMarketingPage__searchResultOverview--${ sanitizeDataGtm(match) }`"
                                tabindex="-1">
                                <svg
                                    aria-hidden="true"
                                    class="stat__svgIcon">
                                    <use href="#iconArrowRightLongBold" />
                                </svg>
                                {{ trans('XMO_OUTLOOK_EXPLORE') }}
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
            <div
                v-if="showAiResults"
                data-fb-container="xmoAiSearchResults__feedback"
                :data-fb-request="searchInput"
                :data-fb-response="JSON.stringify(aiSearchResults)">
                <!-- FeedbackBanner -->
            </div>
            <AiRecommendationsPromo v-if="!showAiResults" />
            <div
                v-if="showAiResults"
                class="xmoAiLoadingBannerFooter">
                <p>
                    {{ trans('XMO_POWERED_BY') }}
                    <span class="xmoAiLoadingBannerFooter__aiText">
                        {{ trans('OPEN_AI') }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    import AiRecommendationsPromo from 'outlook/components/hubPage/aiRecommendationsPromo';
    import { generateMarketName } from 'outlook/utils/generateMarketName';
    import { activateTab, setCurrentHovered } from 'tools/mouseoverAxis';
    import Translator from 'tools/trans';
    import { mapState } from 'vuex';
    // There is not a specific length decided at the moment this should be changed once max length is decided.
    const MAXIMUM_TEXT_LENGTH = 400;
    const FALLBACK_SVG_ICON = 'fallbackIcon';

    export default {
        components: {
            AiRecommendationsPromo,
        },
        props: {
            matchedSearchResults: { type: Array, required: true },
            icons: { type: Object, required: true },
            basePath: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                selectedProduct: this.matchedSearchResults[0],
                currentUid: null,
                elementBaseClass: 'autocompleteList__results--svg',
                iconClassName: '',
                svgBaseClass: 'outlookClusterIcon outlookClusterIcon--',
            };
        },
        computed: {
            ...mapState([
                'searchInput',
                'showAiResults',
                'aiSearchResults',
            ]),
            matches() {
                return this.matchedSearchResults.map((result) => {
                    return { ...result, uid: this.getUniqueId(result) };
                });
            },
            searchResultsHeadline() {
                return this.showAiResults ? this.trans('XMO_SEARCH_AI_RECOMMENDATIONS_TITLE')
                    : this.trans('XMO_MARKET_OUTLOOKS_SEARCH_RESULTS');
            },
        },
        watch: {
            matches() {
                this.selectedProduct = this.matches[0];
            },
        },
        methods: {
            trans: Translator.trans,
            activateTab,
            generateMarketName,
            setCurrentHovered,
            getUniqueId(match) {
                const { uid, id } = match;
                const uidString = `${ uid }${ id }`;

                return parseInt(uidString);
            },
            generateMarketLink(url, slug) {
                return `${ this.basePath }${ url }/${ slug }`;
            },
            setTabActive(match, ref, event) {
                const { uid } = match;
                this.currentUid = this.activateTab(uid, ref, event);
                if (uid === this.currentUid) {
                    this.selectedProduct = this.matches.find((prod) => prod.uid === this.currentUid);

                    return this.selectedProduct;

                }

                return this.selectedProduct;
            },
            changeProduct(match) {
                this.selectedProduct = match;
            },
            formatDescription(description = '') {
                if (description.length > MAXIMUM_TEXT_LENGTH) {
                    return `${ description.substring(0, MAXIMUM_TEXT_LENGTH).trim() }...`;
                }

                return description;
            },
            generateIconClass(match) {
                if (match.uid === this.selectedProduct.uid) {
                    return `${ this.svgBaseClass }${ match.product } ${ this.elementBaseClass }`;
                }

                return this.elementBaseClass;
            },
            sanitizeDataGtm(match) {
                const marketName = match.market.replace(/[^\w\s]/gi, '').replace(/ /g, '-');
                if (this.showAiResults && match.slug) {
                    return `${ marketName }-${ match.slug }`;
                }

                return marketName;
            },
            getSvgIcon(match) {
                if (this.icons[match]) {
                    return this.icons[match].path;
                }

                return this.icons[FALLBACK_SVG_ICON].path;
            },
            formatSearchResultName(string) {
                const modifiedString = string.replace(/\b\w/g, (match) => match.toUpperCase()).replace(/-/g, ' ');

                return modifiedString;
            },
            generateSearchResultName(match) {
                if (this.showAiResults && match.slug) {
                    return `${ match.market } - ${ this.formatSearchResultName(match.slug) }`;
                }

                return match.market;
            },
        },
        created() {
            this.selectedProduct = this.matches[0];
        },
    };
</script>
