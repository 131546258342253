<template>
    <div
        ref="trigger"
        class="vueTooltip__trigger"
        :class="{ 'vueTooltip__trigger--clickable': config.openOnClick }"
        :data-gtm="gtm"
        v-on="!tooltipDisabled ? {
            focusin: () => !config.openOnClick ? showTooltip(true) : null,
            mouseenter: () => !config.openOnClick ? showTooltipDelayed() : null,
            mouseleave: () => !config.openOnClick ? delayHideTooltip() : null,
            click: () => config.openOnClick ? onClick() : null,
        } : {}"
        @keydown.space="onSpace($event)"
        @keydown.tab="onTab($event)"
        @keyup.esc.stop="hideTooltip()">
        <slot
            :show="showTooltip"
            :hide="hideTooltip"></slot>
        <div
            v-if="!tooltipDisabled"
            ref="tooltipWrapper"
            class="vueTooltip__wrap">
            <div
                ref="tooltip"
                role="tooltip"
                class="vueTooltip animated animation--duration-500"
                :class="tooltipClasses"
                :style="tooltipStyles"
                :hidden="tooltipHidden"
                @mouseenter="!config.openOnClick ? blockFadeOut() : setPreventHideOnBlur(true)"
                @mouseleave="!config.openOnClick ? fadeAndHide() : setPreventHideOnBlur(false)"
                @keydown.tab="handleTabInsideTooltip($event)"
                @keydown.esc.stop="fadeAndHide(true)">
                <slot name="content">
                    <p
                        v-if="heading"
                        class="vueTooltip__heading"
                        v-html="heading">
                    </p>

                    <div
                        class="vueTooltip__content"
                        v-html="content"></div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { getOffsets, outerSize } from 'tools/domHelpers';
    import { isMobile } from 'tools/isMobile';
    import { getFocusableElements } from 'tools/getFocusableElements';

    const ANIMATION_DURATION_MS = 500;
    const MOUSEENTER_DELAY_MS = 300;
    const MOUSEMOVE_DELAY_MS = 100;
    const TOOLTIP_OFFSET_PX = 15;

    const DEFAULT_CONFIG = {
        NOT_AVAILABLE_VALUE: 0,
        WIDTH_PX: 250,
        POSITION: 'right',
        AUTO_POSITION: true,
        OPEN_ON_CLICK: false,
        HIDE_ARROW: false,
        SHOW_OVERLAY: false,
        ARROW_LEFT: '50%',
        ALLOW_SPACE: false,
        HIDE_ERROR_IMAGE: false,
    };

    const CLASS_NAMES = {
        ARROW_LEFT: 'arrowLeft',
        ARROW_RIGHT: 'arrowRight',
        ARROW_DOWN: 'arrowDown',
        ARROW_UP: 'arrowUp',
        TRANSITION_LEFT: 'fadeInLeft',
        TRANSITION_RIGHT: 'fadeInRight',
        TRANSITION_DOWN: 'fadeInDown',
        TRANSITION_UP: 'fadeInUp',
        TRANSITION_OUT: 'fadeOut',
        POS_ABSOLUTE: 'pos-absolute',
        HAS_ERROR_IMAGE: 'hasErrorImage',
    };

    const POSITION_CLASSES = {
        right: { arrowClass: CLASS_NAMES.ARROW_LEFT, transitionClass: CLASS_NAMES.TRANSITION_RIGHT },
        left: { arrowClass: CLASS_NAMES.ARROW_RIGHT, transitionClass: CLASS_NAMES.TRANSITION_LEFT },
        top: { arrowClass: CLASS_NAMES.ARROW_DOWN, transitionClass: CLASS_NAMES.TRANSITION_DOWN },
        bottom: { arrowClass: CLASS_NAMES.ARROW_UP, transitionClass: CLASS_NAMES.TRANSITION_UP },
    };

    const FALLBACK_DIRECTIONS = {
        right: ['right', 'left', 'top', 'bottom'],
        left: ['left', 'right', 'top', 'bottom'],
        top: ['top', 'bottom', 'right', 'left'],
        bottom: ['bottom', 'top', 'right', 'left'],
    };

    export default {
        props: {
            gtm: {
                type: String,
                default: '',
            },
            heading: {
                type: String,
                default: '',
            },
            content: {
                type: String,
                default: '',
            },
            settings: {
                type: Object,
                default: () => ({}),
            },
            hideOnMobile: {
                type: Boolean,
                default: false,
            },
            hideOnDesktop: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                config: {
                    width: this.settings?.width ?? DEFAULT_CONFIG.WIDTH_PX,
                    horizontalArrowTopOffset: this.settings?.horizontalArrowTopOffset ?? DEFAULT_CONFIG.NOT_AVAILABLE_VALUE,
                    position: this.settings?.position ?? DEFAULT_CONFIG.POSITION,
                    positionMobile: this.settings?.positionMobile ?? DEFAULT_CONFIG.POSITION,
                    hideArrow: this.settings?.hideArrow ?? DEFAULT_CONFIG.HIDE_ARROW,
                    showOverlay: this.settings?.showOverlay ?? DEFAULT_CONFIG.SHOW_OVERLAY,
                    classes: this.settings?.classes ?? '',
                    autoPosition: this.settings?.autoPosition ?? DEFAULT_CONFIG.AUTO_POSITION,
                    openOnClick: this.settings?.openOnClick ?? DEFAULT_CONFIG.OPEN_ON_CLICK,
                    allowSpaceOnTrigger: this.settings?.allowSpaceOnTrigger ?? DEFAULT_CONFIG.ALLOW_SPACE,
                    hideErrorImage: this.settings?.hideErrorImage ?? DEFAULT_CONFIG.HIDE_ERROR_IMAGE,
                },
                position:
                    (isMobile() ? this.settings?.positionMobile : this.settings?.position) ?? DEFAULT_CONFIG.POSITION,
                fadingDirections: null,
                timeout: 0,
                tooltipDisabled: this.hideOnMobile && isMobile() || this.hideOnDesktop,
                tooltipShown: false,
                tooltipHidden: true,
                fadeOutBlocked: false,
                preventHideOnBlur: false,
                hasErrorImage: false,
            };
        },
        computed: {
            tooltipClasses() {
                const classes = [];
                if (this.fadingDirections) {
                    const directionClasses = POSITION_CLASSES[this.position];
                    classes.push(directionClasses.arrowClass);
                    classes.push(this.tooltipShown ? directionClasses.transitionClass : CLASS_NAMES.TRANSITION_OUT);
                    if (!this.tooltipHidden) {
                        classes.push(CLASS_NAMES.POS_ABSOLUTE);
                    }
                }
                if (this.config.classes.length > 0) {
                    classes.push(this.config.classes);
                }
                if (this.hasErrorImage) {
                    classes.push(CLASS_NAMES.HAS_ERROR_IMAGE);
                }

                return classes;
            },
            tooltipStyles() {
                const width = Math.min(this.config.width, window.innerWidth);
                const styles = { width: `${ width }px` };

                if (this.fadingDirections) {
                    const directionOffsets = this.fadingDirections[this.position];
                    styles.top = directionOffsets?.top ?? 0;
                    styles.left = directionOffsets?.left ?? 0;
                    styles['--arrowLeft'] = directionOffsets?.arrowLeft ?? DEFAULT_CONFIG.ARROW_LEFT;
                }

                return styles;
            },
            elementWithTooltip() {
                return this.$refs.trigger.firstChild;
            },
            innerFocusableElements() {
                if (this.$refs.tooltip) {
                    const focusableElementsInTooltip = getFocusableElements({ parent: this.$refs.tooltip });
                    const slotOrContentWrapper = this.$refs.tooltip.firstChild;

                    const tooltipContentIsScrollable =
                        slotOrContentWrapper.clientHeight < slotOrContentWrapper.scrollHeight
                        || slotOrContentWrapper.clientWidth < slotOrContentWrapper.scrollWidth;

                    if (tooltipContentIsScrollable && slotOrContentWrapper !== focusableElementsInTooltip[0]) {
                        return [
                            slotOrContentWrapper,
                            ...focusableElementsInTooltip,
                        ];
                    }

                    return focusableElementsInTooltip;
                }

                return [];
            },
        },
        methods: {
            onClick() {
                this.tooltipShown ? this.hideTooltip() : this.showTooltip();
            },
            showTooltipDelayed() {
                this.timeout = setTimeout(() => {
                    this.showTooltip();
                }, MOUSEENTER_DELAY_MS);
            },
            delayHideTooltip() {
                setTimeout(() => {
                    this.hideTooltip();
                }, MOUSEMOVE_DELAY_MS);
            },
            fadeAndHide(viaKey = false) {
                this.fadeOutBlocked = false;
                this.hideTooltip();

                if (viaKey) {
                    this.elementWithTooltip?.focus();
                }
            },
            blockFadeOut() {
                this.fadeOutBlocked = true;
            },
            setPreventHideOnBlur(prevent = true) {
                this.preventHideOnBlur = prevent;
            },
            showTooltip(viaKey = false) {
                if (this.$refs.tooltip && !this.tooltipShown) {
                    this.setToolTipContentFocusable();
                    document.body.appendChild(this.$refs.tooltip);
                    this.tooltipHidden = false;
                    this.setSpacings();
                    this.tooltipShown = true;

                    if (viaKey) {
                        this.preventHideOnBlur = true;
                    }
                }
            },
            hideTooltip() {
                if (!this.fadeOutBlocked && this.$refs.tooltipWrapper) {
                    clearTimeout(this.timeout);
                    this.tooltipShown = false;
                    this.timeout = setTimeout(() => {
                        if (!this.tooltipShown) {
                            if (this.$refs.tooltipWrapper) {
                                this.$refs.tooltipWrapper.appendChild(this.$refs.tooltip);
                                this.unsetToolTipContentFocusable();
                            } else {
                                if (this.$refs.tooltip) {
                                    this.$refs.tooltip.remove();
                                }
                            }
                            this.tooltipHidden = true;
                        }
                    }, ANIMATION_DURATION_MS);
                    this.fadeOutBlocked = false;
                }
            },
            hideErrorImage() {
                const image = this.$refs.tooltip.querySelector('img');
                if (!image) {
                    return;
                }
                image.onerror = () => {
                    image.style.display = 'none';
                    this.hasErrorImage = true;
                };
            },
            setSpacings() {
                const triggerPosition = getOffsets(this.elementWithTooltip);
                const triggerWidth = outerSize(this.elementWithTooltip).width;
                const triggerHeight = outerSize(this.elementWithTooltip).height;
                const tooltipWidth = outerSize(this.$refs.tooltip).width;
                const tooltipHeight = outerSize(this.$refs.tooltip).height;
                const smartTop = Math.max(TOOLTIP_OFFSET_PX, triggerPosition.top + this.config.horizontalArrowTopOffset - TOOLTIP_OFFSET_PX);
                let smartLeft = Math.max(TOOLTIP_OFFSET_PX,
                                         triggerPosition.left - (tooltipWidth / 2) + (triggerWidth / 2));
                if (smartLeft + tooltipWidth > window.innerWidth) {
                    smartLeft = Math.max(0, window.innerWidth - tooltipWidth - TOOLTIP_OFFSET_PX);
                }
                const smartArrowLeft = triggerPosition.left + (triggerWidth / 2) - smartLeft;
                this.fadingDirections = {
                    right: {
                        top: `${ smartTop }px`,
                        left: `${ triggerPosition.left + triggerWidth + TOOLTIP_OFFSET_PX }px`,
                    },
                    left: {
                        top: `${ smartTop }px`,
                        left: `${ triggerPosition.left - tooltipWidth - TOOLTIP_OFFSET_PX }px`,
                    },
                    top: {
                        top: `${ triggerPosition.top - tooltipHeight - TOOLTIP_OFFSET_PX }px`,
                        left: `${ smartLeft }px`,
                        arrowLeft: `${ smartArrowLeft }px`,
                    },
                    bottom: {
                        top: `${ triggerPosition.top + triggerHeight + TOOLTIP_OFFSET_PX }px`,
                        left: `${ smartLeft }px`,
                        arrowLeft: `${ smartArrowLeft }px`,
                    },
                };
                if (this.config.autoPosition) {
                    this.setAutoPosition();
                }
            },
            setAutoPosition() {
                const currentFallbackDirections = FALLBACK_DIRECTIONS[this.config.position];
                for (let index = 0; index < currentFallbackDirections.length; index += 1) {
                    const direction = currentFallbackDirections[index];
                    if (!this.isOffscreen(direction)) {
                        this.position = direction;
                        break;
                    }
                }
            },
            isOffscreen(direction) {
                const triggerRect = this.elementWithTooltip.getBoundingClientRect();
                const tooltipRect = this.$refs.tooltip.getBoundingClientRect();

                switch (direction) {
                    case 'right':
                        return (triggerRect.left + triggerRect.width + tooltipRect.width) > window.innerWidth;
                    case 'left':
                        return (triggerRect.left - tooltipRect.width) < 0;
                    case 'top':
                        return (triggerRect.top - tooltipRect.height) < 0;
                    case 'bottom':
                        return (triggerRect.top + triggerRect.height + tooltipRect.height) > window.innerHeight;
                }
            },
            onBlur() {
                if (!this.preventHideOnBlur) {
                    this.hideTooltip();
                }
                this.preventHideOnBlur = false;
            },
            clickOutsideEvent(event) {
                if (
                    !(this.$refs.trigger === event.target || this.$refs.trigger?.contains(event.target)) &&
                    !(this.$refs.tooltip === event.target || this.$refs.tooltip?.contains(event.target))
                ) {
                    this.onBlur();
                }
            },
            onTab(event) {
                if (!this.tooltipShown) {
                    return;
                }

                if (event.shiftKey || (!event.shiftKey && !this.innerFocusableElements.length)) {
                    this.hideTooltip();

                    return;
                }

                if (!event.shiftKey && this.innerFocusableElements.length) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.preventHideOnBlur = true;
                    this.$nextTick(() => {
                        this.innerFocusableElements[0].focus();
                    });
                }
            },
            onSpace(event) {
                if (!this.config.allowSpaceOnTrigger) {
                    event.stopPropagation();
                    event.preventDefault();
                }
                this.showTooltip(true);
            },
            focusNextElementOnPage() {
                const focusableElements = getFocusableElements();

                let currentFocusedIndex;
                focusableElements.some((element, index) => {
                    if (element === this.elementWithTooltip) {
                        currentFocusedIndex = index;

                        return true;
                    }
                });

                if (currentFocusedIndex) {
                    (focusableElements[currentFocusedIndex + 1] ?? focusableElements[currentFocusedIndex]).focus();
                }
            },
            handleTabInsideTooltip(event) {
                if (event.target?.isEqualNode(this.innerFocusableElements[0]) && event.shiftKey) {
                    event.preventDefault();
                    this.elementWithTooltip?.focus();
                }

                const lastInnerFocusableElement = this.innerFocusableElements[this.innerFocusableElements.length - 1];
                if (event.target?.isEqualNode(lastInnerFocusableElement) && !event.shiftKey) {
                    event.preventDefault();
                    this.hideTooltip();
                    this.focusNextElementOnPage();
                }
            },
            setToolTipContentFocusable() {
                this.innerFocusableElements.forEach(focusableElement => {
                    focusableElement.setAttribute('tabindex', '0');
                });
            },
            unsetToolTipContentFocusable() {
                this.innerFocusableElements.forEach(focusableElement => {
                    focusableElement.setAttribute('tabindex', '-1');
                });
            },
        },
        mounted() {
            if (this.config.hideErrorImage) {
                this.hideErrorImage();
            }
            if (this.elementWithTooltip
                && this.elementWithTooltip.nodeType === Node.ELEMENT_NODE
                && !this.elementWithTooltip.dataset.preventTabindex) {
                this.elementWithTooltip.setAttribute('tabindex', 0);
                this.elementWithTooltip.addEventListener('blur', this.onBlur);
            }
            if (this.config.openOnClick) {
                document.body.addEventListener('click', this.clickOutsideEvent);
            }

            this.unsetToolTipContentFocusable();
        },
        beforeDestroy() {
            if (this.$refs.tooltip) {
                this.$refs.tooltip.remove();
            }
            this.elementWithTooltip.removeEventListener('blur', this.onBlur);
            document.body.removeEventListener('click', this.clickOutsideEvent);
        },
    };
</script>
