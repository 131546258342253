import Translator from 'tools/trans';

const DE = 'de';
const LOCALE = window.stGlobals.locale;

export const ICONS = {
    'error': {
        path: `M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311
        0.683417 -0.0976311 1.31658 0.292893 1.70711L2.58579 4L0.292894 6.29289C-0.0976309
        6.68342 -0.0976309 7.31658 0.292894 7.70711C0.683417 8.09763 1.31658 8.09763 1.70711
        7.70711L4 5.41421L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711C8.09763
        7.31658 8.09763 6.68342 7.70711 6.29289L5.41421 4L7.70711 1.70711C8.09763 1.31658 8.09763
        0.683417 7.70711 0.292893C7.31658 -0.0976311 6.68342 -0.0976311 6.29289 0.292893L4 2.58579L1.70711
        0.292893Z`,
    },
    'amo': {
        name: 'SMI_CONTENT_AMO',
        path: `M23,10.22c0-.7-.7-1.18-1.35-.94l-7.24,2.71h-6.41c-.55,0-1,.45-1,1v4c0,.55,.45,1,1,1h1.09l.84,4.2c.09,.47,.5,.8,.98,.8h1.09c.55,0,1-.45,
               1-1v-4h1.41l7.24,2.71c.65,.25,1.35-.24,1.35-.94V10.22Zm-13.51,6.78s.01,0,.02,0h4.49v-4h-6v4h1.49Zm1.42,
               5l-.8-4h1.89v4h-1.09Zm4.09-9.15l7-2.62v9.56l-7-2.62v-4.31Z M25,13.5c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5v3c0,.28,
               .22,.5,.5,.5s.5-.22,.5-.5v-3Z`,
    },
    'cmo': {
        name: 'SMI_CONTENT_CMO',
        path: `M14,14.5c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5c0,1.35,.41,2.26,1.05,2.81,.62,.54,1.38,.69,1.95,.69s1.33-.14,1.95-.69c.64-.56,1.05-1.46,
               1.05-2.81,0-.28-.22-.5-.5-.5s-.5,.22-.5,.5c0,1.15-.34,1.74-.7,2.06-.38,.33-.87,.44-1.3,.44s-.92-.11-1.3-.44c-.36-.32-.7-.91-.7-2.06Z
               M23.71,11l-2.71-2.71c-.19-.19-.44-.29-.71-.29H11.71c-.27,0-.52,.11-.71,.29l-2.71,2.71c-.19,.19-.29,.44-.29,.71v11.29c0,.55,.45,1,1,
               1h14c.55,0,1-.45,1-1V11.71c0-.27-.11-.52-.29-.71Zm-12-2h8.59l2,2H9.71l2-2Zm11.29,14H9V12h14v11Z`,
    },
    'co': {
        name: 'SMI_CONTENT_CO',
        path: `M25,16c0,4.97-4.03,9-9,9s-9-4.03-9-9S11.03,7,16,7s9,4.03,9,9Zm-9.8-7.46c.37-.42,.65-.54,.8-.54s.44,.12,.8,.54c.35,.4,
               .72,1.01,1.05,1.79,.61,1.45,1.08,3.45,1.14,5.67h-5.99c.06-2.22,.53-4.21,1.14-5.67,.33-.78,.7-1.39,1.05-1.79Zm-3.19,7.46c.06-2.35,
               .55-4.48,1.22-6.06,.29-.68,.61-1.26,.95-1.73-3.54,.82-6.18,4-6.18,7.79h4.01Zm-3.94,1h3.94c.06,2.32,.55,4.22,1.23,5.59,.22,.43,.46,
               .82,.71,1.15-3.11-.82-5.48-3.47-5.89-6.73Zm4.95,0h5.99c-.06,2.19-.53,3.93-1.13,5.14-.32,.65-.68,1.13-1.02,1.44-.35,.31-.64,.42-.84,
               .42s-.5-.11-.84-.42c-.34-.31-.7-.79-1.02-1.44-.6-1.21-1.07-2.95-1.13-5.14Zm6.99,0c-.06,2.32-.55,4.22-1.23,5.59-.22,.43-.46,.82-.71,1.15,
               3.11-.82,5.48-3.47,5.89-6.73h-3.94Zm4.01-1c0-3.79-2.64-6.97-6.18-7.79,.35,.47,.67,1.06,.95,1.73,.67,1.58,1.16,3.71,1.22,6.06h4.01Z`,
    },
    'hmo': {
        name: 'SMI_CONTENT_HMO',
        path: `M8.25,10.55c.86-1.01,2.14-1.55,3.75-1.55,1.15,0,2.02,.44,2.73,1.15,.49,.49,.9,1.1,1.27,1.78,.38-.67,.78-1.29,1.27-1.78,
              .71-.71,1.58-1.15,2.73-1.15,2.58,0,5,2.24,5,5.5,0,.99-.35,2.01-.9,3-.55,.99-1.31,1.98-2.17,2.9-1.72,1.85-3.89,3.51-5.68,
               4.53-.15,.09-.34,.09-.5,0-1.79-1.02-3.96-2.68-5.68-4.53-.86-.93-1.62-1.91-2.17-2.9-.55-.99-.9-2.01-.9-3,0-1.58,.39-2.96,
               1.25-3.95Zm.76,.65c-.65,.76-1,1.88-1,3.3,0,.76,.27,1.61,.77,2.52,.5,.9,1.2,1.82,2.03,2.71,1.57,1.69,3.54,3.22,5.2,4.2,
               1.66-.98,3.62-2.51,5.2-4.2,.83-.89,1.53-1.81,2.03-2.71,.5-.9,.77-1.76,.77-2.52,0-2.74-2.01-4.5-4-4.5-.85,0-1.48,.31-2.02,.85-.56,
               .56-1.03,1.37-1.53,2.37-.08,.17-.26,.28-.45,.28s-.36-.11-.45-.28c-.5-1-.97-1.81-1.53-2.37-.54-.54-1.17-.85-2.02-.85-1.38,0-2.36,
               .46-3,1.2Z`,
    },
    'io': {
        name: 'SMI_CONTENT_IO',
        path: `M8,9c0-.55,.45-1,1-1h4c.55,0,1,.45,1,1v6.96l4.19-3.35c.15-.12,.36-.14,.53-.06,.17,.08,.28,.26,.28,
               .45v2.96l4.19-3.35c.15-.12,.36-.14,.53-.06,.17,.08,.28,.26,.28,.45v10c0,.55-.45,1-1,1H9c-.55,0-1-.45-1-1V9Zm5,
               0h-4v14h14V14.04l-3.38,2.7c-.65,.52-1.62,.06-1.62-.78v-1.92l-3.38,2.7c-.65,.52-1.62,.06-1.62-.78v-6.96Z`,
    },
    'mmo': {
        name: 'SMI_CONTENT_MMO',
        path: `M11.5,14c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h9c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5H11.5Z M9.5,17.5c0-.55,.45-1,1-1s1,.45,
               1,1-.45,1-1,1-1-.45-1-1Z M21.5,16.5c-.55,0-1,.45-1,1s.45,1,1,1,1-.45,1-1-.45-1-1-1Z M11.94,9c-1.08,0-2.03,.69-2.37,
               1.71l-.43,1.29h-.64c-.83,0-1.5,.67-1.5,1.5,0,.65,.41,1.2,.99,1.41-.63,.64-.99,1.51-.99,2.44v1.55c0,1.13,.89,2.05,2,2.1v1c0,
               .55,.45,1,1,1h2c.55,0,1-.45,1-1v-1h6v1c0,.55,.45,1,1,1h2c.55,0,1-.45,1-1v-1c1.11-.05,2-.97,2-2.1v-1.55c0-.93-.37-1.8-.99-2.44,
               .58-.21,.99-.76,.99-1.41,0-.83-.67-1.5-1.5-1.5h-.64l-.43-1.29c-.34-1.02-1.3-1.71-2.37-1.71H11.94Zm-1.42,2.03c.2-.61,.78-1.03,
               1.42-1.03h8.12c.65,0,1.22,.41,1.42,1.03l.49,1.48c.1,.3,.38,.5,.69,.5h.83c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5h-1c-.23,
               0-.43,.16-.49,.39-.05,.23,.06,.46,.26,.56l.34,.17c.85,.42,1.38,1.29,1.38,2.24v1.55c0,.61-.49,1.1-1.1,1.1-.5,
               0-.9,.4-.9,.9v1.1h-2v-1c0-.55-.45-1-1-1h-6c-.55,0-1,.45-1,1v1h-2v-1.1c0-.5-.4-.9-.9-.9-.61,0-1.1-.49-1.1-1.1v-1.55c0-.95,
               .54-1.81,1.38-2.24l.34-.17c.21-.1,.32-.34,.26-.56-.05-.23-.25-.39-.49-.39h-1c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h.83c.31,0,
               .59-.2,.69-.5l.49-1.48Z`,
    },
    'fmo': {
        name: 'SMI_CONTENT_FMO',
        path: `M16 8c-4.28 0-8 3.198-8 8 0 2.436.81 4.428 2.19 5.809C11.573 23.19 13.564 24 16 24c2.436 0 4.428-.81 5.809-2.191
               1.38-1.381 2.19-3.373 2.19-5.809a.5.5 0 1 1 1 0c0 2.664-.89 4.922-2.483 6.516C20.922 24.11 18.663 25 16 25c-2.664
               0-4.922-.89-6.516-2.484C7.89 20.922 7 18.664 7 16c0-5.398 4.213-9 9-9 3.135 0 6.137 1.033 8 3.656V8.5a.5.5 0 0 1 1
               0v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1h1.51C21.377 8.888 18.82 8 16 8z M16 9.5a.5.5 0 0 0-.5.5v1.02c-.602.048-1.16.194-1.603.51-.597.427-.897
               1.097-.897 1.97 0 .864.29 1.622.834 2.166.436.436 1.01.71 1.666.8v3.489c-.978-.183-1.5-.89-1.5-1.455a.5.5 0 0 0-1 0c0 1.21 1.05 2.266
               2.5 2.466V22a.5.5 0 0 0 1 0v-1.02c.602-.047 1.16-.194 1.603-.51.597-.427.897-1.097.897-1.97 0-.864-.29-1.622-.834-2.166-.436-.436-1.01-.71-1.666-.8v-3.489c.978.183 1.5.89 1.5 1.455a.5.5
               0 0 0 1 0c0-1.21-1.05-2.266-2.5-2.466V10a.5.5 0 0 0-.5-.5zm.5 7.047v3.43c.452-.045.79-.155 1.022-.321.278-.199.478-.53.478-1.156
               0-.636-.21-1.128-.541-1.459a1.842 1.842 0 0 0-.959-.494zm-2.022-4.203c.233-.166.57-.276 1.022-.32v3.429a1.842 1.842 0 0 1-.959-.494C14.21
               14.628 14 14.136 14 13.5c0-.627.2-.957.478-1.156z`,
    },
    'tmo': {
        name: 'SMI_CONTENT_TMO',
        path: `M9,19v-7c0-1.1,.9-2,2-2h10c1.1,0,2,.9,2,2v7h1c.82,0,1.29,.94,.8,1.6l-.45,.6c-.38,.5-.97,.8-1.6,.8H9.25c-.63,
               0-1.22-.3-1.6-.8l-.45-.6c-.49-.66-.02-1.6,.8-1.6h1Zm1-7c0-.55,.45-1,1-1h10c.55,0,1,.45,1,1v7H10v-7Zm-2,8H24l-.45,.6c-.19,.25-.49,
               .4-.8,.4H9.25c-.31,0-.61-.15-.8-.4l-.45-.6Z`,
    },
    'emo': {
        name: 'SMI_CONTENT_EMO',
        path: `M23 8.5C23 8.22386 22.7761 8 22.5 8H20.9949C20.1788 8 19.4736 8.57012 19.3026 9.36812L19.1672 10H8.5C8.35303 10 8.2135 10.0647 8.11849
               10.1768C8.02349 10.289 7.98264 10.4372 8.00681 10.5822L8.7497 15.0396C8.93825 16.1708 9.91704 17 11.0639 17H14.3615L14.7615
               18H10.5C10.2238 18 9.99997 18.2239 9.99997 18.5C9.99997 18.7761 10.2238 19 10.5 19H15.1615L17.0357 23.6857C17.1137 23.8805 17.3049
               24.006 17.5147 23.9998C17.7244 23.9936 17.908 23.8572 17.9743 23.6581L19.2185 19.9256L23.1464 23.8536C23.3417 24.0488 23.6583 24.0488
               23.8535 23.8536C24.0488 23.6583 24.0488 23.3417 23.8535 23.1465L19.9256 19.2185L23.6581 17.9744C23.8572 17.908 23.9936 17.7244 23.9998
               17.5147C24.0059 17.305 23.8805 17.1137 23.6857 17.0358L19.0772 15.1924L20.2804 9.57765C20.3526 9.24072 20.6504 9 20.9949 9H22.5C22.7761
               9 23 8.77614 23 8.5ZM9.09023 11H18.9529L18.1353 14.8156L13.6857 13.0358C13.5 12.9615 13.2879 13.005 13.1464 13.1465C13.005 13.2879 12.9615
               13.5 13.0357 13.6857L13.9615 16H11.0639C10.4059 16 9.84428 15.5243 9.73609 14.8752L9.09023 11ZM14.3975 14.3975L22.0469 17.4573L18.8419
               18.5257C18.6926 18.5754 18.5754 18.6926 18.5256 18.8419L17.4573 22.047L15.9684 18.3248C15.9658 18.3178 15.963 18.3109 15.9601 18.3041L14.3975
               14.3975Z M12 20C12 19.7239 11.7761 19.5 11.5 19.5C11.2238 19.5 11 19.7239 11 20V20.5C11 20.7761 11.2238 21 11.5 21C11.7761 21 12 20.7761 12
               20.5V20Z M15 20C15 19.7239 14.7761 19.5 14.5 19.5C14.2238 19.5 14 19.7239 14 20V20.5C14 20.7761 14.2238 21 14.5 21C14.7761 21 15 20.7761 15 20.5V20Z`,
    },
    'fallbackIcon': {
        name: '',
        path: `M13,14c0-.55,.45-1,1-1h4c.55,0,1,.45,1,1v4c0,.55-.45,1-1,1h-4c-.55,0-1-.45-1-1v-4Zm5,0h-4v4h4v-4Z
               M14,7.5c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5v2.5h-2c-.55,0-1,.45-1,1v2h-2.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h2.5v4h-2.5c-.28,
               0-.5,.22-.5,.5s.22,.5,.5,.5h2.5v2c0,.55,.45,1,1,1h2v2.5c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-2.5h4v2.5c0,.28,.22,.5,.5,.5s.5-.22,
               .5-.5v-2.5h2c.55,0,1-.45,1-1v-2h2.5c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5h-2.5v-4h2.5c.28,0,
               .5-.22,.5-.5s-.22-.5-.5-.5h-2.5v-2c0-.55-.45-1-1-1h-2v-2.5c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5v2.5h-4v-2.5Zm-3,3.5v10h10V11H11Z`,
    },
};

export const SCREENS = [
    {
        'png': ['CMO.png', 'IO.png', 'CO.png', 'AMO.png', 'MMO.png', 'TMO.png', 'HMO.png', 'FMO.png', 'EMO.png'],
    },
    {
        'webp': ['CMO.webp', 'IO.webp', 'CO.webp', 'AMO.webp', 'MMO.webp', 'TMO.webp', 'HMO.webp', 'FMO.webp', 'EMO.png'],
    },
];
export const MARKET_DIRECTORIES = [
    {
        'png': ['CMO.png', 'IO.png', 'CO.png', 'AMO.png', 'MMO.png', 'TMO.png', 'HMO.png', 'FMO.png', 'EMO.png'],
    },
    {
        'webp': ['CMO.webp', 'IO.webp', 'CO.webp', 'AMO.webp', 'MMO.webp', 'TMO.webp', 'HMO.webp', 'FMO.webp', 'EMO.png'],
    },
];

export const POPULAR_MARKETS = [
    {
        name: Translator.trans('XMO_HUBPAGE_POPULAR_MARKETS_DIGITAL_PAYMENTS'),
        dePath: 'fmo/digital-payments/weltweit',
        enPath: 'fmo/digital-payments/worldwide',
    },
    {
        name: Translator.trans('XMO_HUBPAGE_POPULAR_MARKETS_HEALTH'),
        dePath: 'hmo/digital-health/weltweit',
        enPath: 'hmo/digital-health/worldwide',
    },
    {
        name: Translator.trans('XMO_HUBPAGE_POPULAR_MARKETS_CARS'),
        dePath: 'mmo/automobile/weltweit',
        enPath: 'mmo/passenger-cars/worldwide',
    },
    {
        name: Translator.trans('XMO_HUBPAGE_POPULAR_MARKETS_SOCIAL_MEDIA'),
        dePath: 'amo/werbung/social-media-werbung/weltweit',
        enPath: 'amo/advertising/social-media-advertising/worldwide',
    },
];

export const QUICKSTART_ITEMS = [
    { name: 'CMO_TITLE', shortName: Translator.trans('NAVIGATION_CMO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_CMO_TEASER'), shortTitle: 'cmo', path: 'consumer-markets' },
    { name: 'CO_TITLE', shortName: Translator.trans('NAVIGATION_CO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_CO_TEASER'), shortTitle: 'co', path: 'country-outlook' },
    { name: 'HMO_TITLE', shortName: Translator.trans('NAVIGATION_HMO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_HMO_TEASER'), shortTitle: 'hmo', path: 'health-markets' },
    { name: 'MMO_TITLE', shortName: Translator.trans('NAVIGATION_MMO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_MMO_TEASER'), shortTitle: 'mmo', path: 'mobility-markets' },
    { name: 'TMO_TITLE', shortName: Translator.trans('NAVIGATION_TMO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_TMO_TEASER'), shortTitle: 'tmo', path: 'technology-outlook' },
    { name: 'AMO_TITLE', shortName: Translator.trans('NAVIGATION_AMO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_AMO_TEASER'), shortTitle: 'amo', path: 'advertising-media' },
    { name: 'IO_TITLE', shortName: Translator.trans('NAVIGATION_IO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_IO_TEASER'), shortTitle: 'io', path: 'industry-outlook' },
    { name: 'FMO_TITLE', shortName: Translator.trans('NAVIGATION_FMO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_FMO_TEASER'), shortTitle: 'fmo', path: 'financial-markets' },
    { name: 'EMO_TITLE', shortName: Translator.trans('NAVIGATION_EMO'), teaser: Translator.trans('XMO_HUBPAGE_QUICKSTART_EMO_TEASER'), shortTitle: 'emo', path: 'ecommerce-outlook' },
];

export const HIGHLIGHTS_SECTIONS_DATA = {
    'cmo': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_CMO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_CMO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_CMO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_CMO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'fmo': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_FMO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_FMO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_CMO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_FMO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'io': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_IO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_IO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_IO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_IO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_IO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'co': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_CO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_CO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_CO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_CO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'amo': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_AMO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_AMO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_AMO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_AMO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'mmo': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_MMO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_MMO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_MMO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_MMO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'tmo': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_TMO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_TMO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_TMO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_TMO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'hmo': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_HMO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_HMO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_HMO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_HMO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
    'emo': [
        {
            name: 'marketSegments',
            title: Translator.trans('XMO_HIGHLIGHTS_MARKET_PENETRATION'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GLOBAL_COVERAGE_TITLE'),
            text: Translator.trans('XMO_EMO_HIGHLIGHTS_MARKET_PENETRATION_TEXT'),
        },
        {
            name: 'globalCoverage',
            title: Translator.trans('XMO_HIGHLIGHTS_GLOBAL_COVERAGE'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_COVERAGE_TITLE'),
            text: Translator.trans('XMO_EMO_HIGHLIGHTS_MARKET_GLOBAL_COVERAGE_TEXT'),
        },
        {
            name: 'keyPlayers',
            title: Translator.trans('XMO_HIGHLIGHTS_KEY_PLAYERS'),
            subTitle: Translator.trans('XMO_USP_HIGHLIGHTS_MARKET_KEY_PLAYERS_SUBTITLE'),
            text: Translator.trans('XMO_EMO_HIGHLIGHTS_MARKET_KEY_PLAYERS_TEXT'),
        },
        {
            name: 'specialKpis',
            title: Translator.trans('XMO_HIGHLIGHTS_KPI'),
            subTitle: Translator.trans('XMO_HIGHLIGHTS_USP_GEO_FORECAST_TITLE'),
            text: Translator.trans('XMO_EMO_HIGHLIGHTS_MARKET_KPI_TEXT'),
        },
    ],
};

export const HIGHLIGHTS_POPULAR_MARKETS = {
    'cmo': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_FOOD'),
            slug: LOCALE === DE ? 'cmo/lebensmittel/weltweit' : 'cmo/food/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_BEAUTY_CARE'),
            slug: LOCALE === DE ? 'cmo/beauty-personal-care/usa' : 'cmo/beauty-personal-care/united-states',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_ALCOHOLIC_DRINKS'),
            slug: LOCALE === DE ? 'cmo/alkoholische-getraenke/weltweit' : 'cmo/alcoholic-drinks/worldwide',
        },
    ],
    'io': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_AGRICULTURE'),
            slug: LOCALE === DE ? 'io/landwirtschaft/vereinigtes-koenigreich' : 'io/agriculture/united-kingdom',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_MANUFACTURING'),
            slug: LOCALE === DE ? 'io/verarbeitendes-gewerbe/weltweit' : 'io/manufacturing/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_ENERGY'),
            slug: LOCALE === DE ? 'io/energie/weltweit' : 'io/energy/worldwide',
        },
    ],
    'co': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_SOCIOECONOMIC_INDICATORS'),
            slug: LOCALE === DE ? 'co/soziooekonomische-indikatoren/usa' : 'co/socioeconomic-indicators/united-states',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_MACROECONOMIC_INDICATORS'),
            slug: LOCALE === DE ? 'co/makrooekonomische-indikatoren/usa' : 'co/macroeconomic-indicators/united-states',
        },
    ],
    'amo': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_SOCIAL_MEDIA_ADVERTISING'),
            slug: LOCALE === DE ? 'amo/werbung/social-media-werbung/weltweit' : 'amo/advertising/social-media-advertising/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_INFLUENCER_ADVERTISING'),
            slug: LOCALE === DE ? 'amo/werbung/influencer-werbung/weltweit' : 'amo/advertising/influencer-advertising/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_DIGITAL_MUSIC'),
            slug: LOCALE === DE ? 'amo/medien/musik-radio-podcasts/digitale-musik/weltweit' : 'amo/media/music-radio-podcasts/digital-music/worldwide',
        },
    ],
    'mmo': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_PASSENGER_CARS'),
            slug: LOCALE === DE ? 'mmo/automobile/weltweit' : 'mmo/passenger-cars/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_ELECTRIC_VEHICLES'),
            slug: LOCALE === DE ? 'mmo/elektrofahrzeuge/weltweit' : 'mmo/electric-vehicles/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_SHARED_RIDES'),
            slug: LOCALE === DE ? 'mmo/shared-mobility/gemeinsame-fahrten/weltweit' : 'mmo/shared-mobility/shared-rides/worldwide',
        },
    ],
    'tmo': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_CYBER_SECURITY'),
            slug: LOCALE === DE ? 'tmo/cybersecurity/weltweit' : 'tmo/cybersecurity/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_INDUSTRIAL_ROBOTICS'),
            slug: LOCALE === DE ? 'tmo/robotik/industrierobotik/weltweit' : 'tmo/robotics/industrial-robotics/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_SOFTWARE'),
            slug: LOCALE === DE ? 'tmo/software/weltweit' : 'tmo/software/worldwide',
        },
    ],
    'hmo': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_MEDICAL_TECHNOLOGY'),
            slug: LOCALE === DE ? 'hmo/medizintechnik/weltweit' : 'hmo/medical-technology/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_COVID_VACCINES'),
            slug: LOCALE === DE ? 'hmo/pharmazeutika/impfstoffe/covid-19-impfstoffe/weltweit' : 'hmo/pharmaceuticals/vaccines/covid-19-vaccines/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_OTC_PHARMA'),
            slug: LOCALE === DE ? 'hmo/otc-pharma/weltweit' : 'hmo/otc-pharmaceuticals/worldwide',
        },
    ],
    'fmo': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_RETAIL_COMMERCIAL_BANKING'),
            slug: LOCALE === DE ? 'fmo/retail-commercial-banking/weltweit' : 'fmo/retail-commercial-banking/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_CAPITAL_RAISING'),
            slug: LOCALE === DE ? 'fmo/capital-raising/weltweit' : 'fmo/capital-raising/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_REAL_ESTATE'),
            slug: LOCALE === DE ? 'fmo/immobilien/weltweit' : 'fmo/real-estate/worldwide',
        },
    ],
    'emo': [
        {
            name: Translator.trans('XMO_HIGHLIGHTS_FASHION'),
            slug: LOCALE === DE ? 'emo/mode/weltweit' : 'emo/fashion/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_ELECTRONICS'),
            slug: LOCALE === DE ? 'emo/elektroartikel/weltweit' : 'emo/electronics/worldwide',
        },
        {
            name: Translator.trans('XMO_HIGHLIGHTS_FURNITURE'),
            slug: LOCALE === DE ? 'emo/moebel/weltweit' : 'emo/furniture/worldwide',
        },
    ],
};
export const PPT_DOWNLOAD_AVAILABLE_MARKETS = ['CMO', 'MMO'];

export function outlookAbbreviationsMap() {
    const abbreviationMap = new Map();
    for (const abbreviation of ['cmo', 'mmo', 'tmo', 'hmo', 'amo', 'co', 'io', 'fmo']) {
        if (abbreviation === 'fmo') {
            abbreviationMap.set(abbreviation, 'financial');
            continue;
        }
        abbreviationMap.set(abbreviation, abbreviation);
    }

    return abbreviationMap;
}
