import Translator from 'tools/trans';

const generateMarketName = (matchProduct: string): string => {
    switch (matchProduct) {
        case 'dmo':
        case 'DMO_TITLE':
            return Translator.trans('DMO_TITLE');
        case 'cmo':
        case 'CMO_TITLE':
            return Translator.trans('CMO_TITLE');
        case 'co':
        case 'CO_TITLE':
            return Translator.trans('CO_TITLE');
        case 'mmo':
        case 'MMO_TITLE':
            return Translator.trans('MMO_TITLE');
        case 'amo':
        case 'AMO_TITLE':
            return Translator.trans('AMO_TITLE');
        case 'tmo':
        case 'TMO_TITLE':
            return Translator.trans('TMO_TITLE');
        case 'io':
        case 'IO_TITLE':
            return Translator.trans('IO_TITLE');
        case 'hmo':
        case 'HMO_TITLE':
            return Translator.trans('HMO_TITLE');
        case 'fmo':
        case 'FMO_TITLE':
            return Translator.trans('FMO_TITLE');
        case 'emo':
        case 'EMO_TITLE':
            return Translator.trans('EMO_TITLE');
        default:
            return '';
    }
};

const generateMarketSubTitle = (matchMarket: string): string => {
    switch (matchMarket) {
        case 'dmo':
            return Translator.trans('DMO_SHORT_DESCRIPTION');
        case 'cmo':
            return Translator.trans('CMO_FILTER_SUBLINE');
        case 'co':
            return Translator.trans('CO_SHORT_DESCRIPTION');
        case 'mmo':
            return Translator.trans('MMO_SHORT_DESCRIPTION');
        case 'amo':
            return Translator.trans('AMO_SHORT_DESCRIPTION');
        case 'tmo':
            return Translator.trans('TMO_SHORT_DESCRIPTION');
        case 'io':
            return Translator.trans('IO_SHORT_DESCRIPTION');
        case 'hmo':
            return Translator.trans('HMO_SHORT_DESCRIPTION');
        case 'fmo':
            return Translator.trans('FMO_SHORT_DESCRIPTION');
        case 'emo':
            return Translator.trans('EMO_SHORT_DESCRIPTION');
        default:
            return '';
    }
};

const generateMarketButtonText = (shortName: string): string => {
    switch (shortName) {
        case 'dmo':
            return Translator.trans('NAVIGATION_DMO');
        case 'cmo':
            return Translator.trans('NAVIGATION_CMO');
        case 'co':
            return Translator.trans('NAVIGATION_CO');
        case 'mmo':
            return Translator.trans('NAVIGATION_MMO');
        case 'amo':
            return Translator.trans('NAVIGATION_AMO');
        case 'tmo':
            return Translator.trans('NAVIGATION_TMO');
        case 'io':
            return Translator.trans('NAVIGATION_IO');
        case 'hmo':
            return Translator.trans('NAVIGATION_HMO');
        case 'fmo':
            return Translator.trans('NAVIGATION_FMO');
        case 'emo':
            return Translator.trans('NAVIGATION_EMO');
        default:
            return '';
    }
};

export { generateMarketName, generateMarketSubTitle, generateMarketButtonText };
