var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.fieldType !== "hidden",
          expression: "fieldType !== 'hidden'",
        },
      ],
      staticClass: "formInput__wrapper vueFloaty",
    },
    [
      _vm.separateLabel && _vm.fieldLabelVisible
        ? _c(
            "label",
            { staticClass: "hl-module", attrs: { for: _vm.fieldId } },
            [_vm._v("\n        " + _vm._s(_vm.fieldLabel) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.lazy",
            value: _vm.fieldValue,
            expression: "fieldValue",
            modifiers: { lazy: true },
          },
        ],
        ref: _vm.fieldId,
        class: _vm.concattedClasses,
        attrs: {
          id: _vm.fieldId,
          type: _vm.fieldType,
          name: _vm.fieldName,
          pattern: _vm.fieldPattern,
          title: _vm.fieldTitle,
          required: _vm.fieldRequired,
          "aria-invalid": `${_vm.ariaInvalidStatus}`,
          "aria-describedBy": _vm.ariaInvalidStatus
            ? `${_vm.fieldId}_describedBy`
            : null,
          maxlength: _vm.fieldMaxlength,
          minlength: _vm.fieldMinlength,
          disabled: _vm.fieldDisabled,
          readonly: _vm.fieldReadonly,
          placeholder: _vm.placeholder,
          autocomplete: _vm.autocomplete,
        },
        domProps: { value: _vm.fieldValue },
        on: {
          input: _vm.input,
          focus: _vm.focusInput,
          blur: _vm.blurInput,
          keyup: _vm.keyup,
          change: function ($event) {
            _vm.fieldValue = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      !_vm.separateLabel && _vm.fieldLabelVisible
        ? _c(
            "label",
            {
              class: {
                vueFloaty__label: true,
                "vueFloaty__label--active": _vm.floatActive,
                "text-color--primary": _vm.isActive,
              },
              attrs: { for: _vm.fieldId },
            },
            [
              _vm._v("\n        " + _vm._s(_vm.fieldLabel) + "\n        "),
              _vm.fieldRequired === true
                ? _c("span", { staticClass: "input__required" }, [
                    _vm._v("*\n            "),
                    _c("span", { staticClass: "visually-hidden" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.trans("LABEL_TEXT_SR_REQUIRED")) +
                          "\n            "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ariaInvalidStatus === true && _vm.fieldAriaInvalid !== false
                ? _c(
                    "span",
                    {
                      staticClass: "visually-hidden",
                      attrs: { id: `${_vm.fieldId}_describedBy` },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.trans("FORM_REGISTER_FIELD_EMPTY")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }