import Vue from 'vue';
import Vuex from 'vuex';
import { getFromTemplate, getPath } from 'tools/getFromHtml';
import { isMobile } from 'tools/isMobile';
import { isIE } from 'tools/isUserAgent';

Vue.use(Vuex);

const USER_RIGHTS = getUserRights();
const CURRENT_OUTLOOK = getFromTemplate('var', 'currentOutlook');
const IS_MOBILE = isMobile();
const IS_IE = isIE();
const PAYWALL_TYPE_A = 'paywallA';
const PAYWALL_TYPE_B = 'paywallB';
const LOAD_HIGHCHARTS = 'loadHighCharts';
const marketData = getFromTemplate('var', 'marketData') ?
    JSON.parse(getFromTemplate('var', 'marketData')) : null;
const VIEW_TYPE = marketData ? marketData.viewType : '';

const FAKE_VIEW_TYPE = 'fake';
const CUSTOM_VIEW_TYPE = 'custom';
const isPaywallB = getPath('paywallType') === PAYWALL_TYPE_B;
const isPaywallA = getPath('paywallType') === PAYWALL_TYPE_A;
const CAN_LOAD_HIGHCHARTS = getPath('canLoadHighCharts') === LOAD_HIGHCHARTS;
const IS_PAYWALL_VIEW = VIEW_TYPE === FAKE_VIEW_TYPE;

// PITCCP-243 / PITCCP-427 - Conversion Teaser
const conversionTeaserElement = document.querySelector('#conversionTeaser');
let conversionTeaserContent = null;
if (conversionTeaserElement) {
    conversionTeaserContent = conversionTeaserElement.outerHTML;
}

const outlookStore = new Vuex.Store({
    state: {
        citationModalShown: false,
        conversionModalShown: false,
        dropdownModalShown: false,
        isRegionComparison: false,
        comparedRegions: null,
        conversionModalCtaGtm: '',
        userRights: USER_RIGHTS,
        currentOutlook: CURRENT_OUTLOOK,
        currentRegionId: null,
        isMobile: IS_MOBILE,
        isIE: IS_IE,
        isPaywallA,
        isPaywallB,
        PAYWALL_TYPE_B,
        CAN_LOAD_HIGHCHARTS,
        OUTLOOK_BASE_PATH: getPath('outlookHubPage'),
        isFakeViewType: IS_PAYWALL_VIEW,
        VIEW_TYPE,
        CUSTOM_VIEW_TYPE,
        outlookPathAndCurrency: getOutlookPathAndCurrency(),
        isCustomView: isCustomView(),
        includedRegions: null,
        excludedRegions: null,
        baseRegion: null,
        userCustomRegions: null,
        isRedirecting: false,
        doesUserOwnRegion: marketData?.doesUserOwnRegion ? marketData?.doesUserOwnRegion : false,
        promotedMarketsAndRegionsRequestPath: null,
        userId: JSON.parse(getFromTemplate('var', 'userId')),
        isAppUser: getFromTemplate('var', 'isAppUser'),
        trackContentDownloadApi: getPath('trackContentDownloadApi'),
        startFullPageDownload: false,
        startCustomDataExport: false,
        outlookActionsCurrency: null,
        canViewGraphDetails: canViewGraphDetails(),
        isPPTDownloadAvailable: false,
        pptDownloadPath: '',
        isPptDownloadGroup: getPath('pptTestGroup') === 'B',
        conversionTeaserContent: conversionTeaserContent,
        canRequestMarketNavigationData: false,
        navigationData: [],
        // BE will provide this information later on.
        allBrands: {
            'id': 1,
            'uniqueId': 1,
            'slug': 'mmo/automobile',
            'name': 'all',
            'status': 'active',
            'available': true,
        },
    },
    mutations: {
        SET_CITATION_MODAL_SHOWN(state, citationModalShown) {
            state.citationModalShown = citationModalShown;
        },
        SET_CONVERSION_MODAL_SHOWN(state, conversionModalShown) {
            state.conversionModalShown = conversionModalShown;
        },
        SET_DROPDOWN_MODAL_SHOWN(state, dropdownModalShown) {
            state.dropdownModalShown = dropdownModalShown;
        },
        SET_IS_REGION_COMPARISON(state, isRegionComparison) {
            state.isRegionComparison = isRegionComparison;
        },
        SET_COMPARED_REGIONS(state, comparedRegions) {
            state.comparedRegions = comparedRegions;
        },
        SET_CONVERSION_MODAL_CTA_GTM(state, conversionModalCtaGtm) {
            state.conversionModalCtaGtm = conversionModalCtaGtm;
        },
        SET_USER_RIGHTS(state, userRights) {
            state.userRights = userRights;
        },
        SET_CURRENT_REGION_ID(state, currentRegionId) {
            state.currentRegionId = currentRegionId;
        },
        SET_INCLUDED_REGIONS(state, includedRegions) {
            state.includedRegions = includedRegions;
        },
        SET_EXCLUDED_REGIONS(state, excludedRegions) {
            state.excludedRegions = excludedRegions;
        },
        SET_BASE_REGION(state, baseRegion) {
            state.baseRegion = baseRegion;
        },
        SET_REQUEST_LOADING_STATE(state, isRedirecting) {
            state.isRedirecting = isRedirecting;
        },
        SET_PROMOTED_MARKETS_AND_REGIONS_REQUEST_PATH(state, path) {
            state.promotedMarketsAndRegionsRequestPath = path;
        },
        SET_FULL_PAGE_DOWNLOAD(state, startFullPageDownload) {
            state.startFullPageDownload = startFullPageDownload;
        },
        SET_CUSTOM_DATA_EXPORT(state, startCustomDataExport) {
            state.startCustomDataExport = startCustomDataExport;
        },
        SET_OUTLOOK_ACTIONS_CURRENCY(state, outlookActionsCurrency) {
            state.outlookActionsCurrency = outlookActionsCurrency;
        },
        SET_OUTLOOK_PPT_DOWNLOAD_AVAILABLE(state, available) {
            state.isPPTDownloadAvailable = available;
        },
        SET_OUTLOOK_PPT_DOWNLOAD_PATH(state, pptDownloadPath) {
            state.pptDownloadPath = pptDownloadPath;
        },
        SET_CAN_REQUEST_MARKET_NAVIGATION_DATA(state, canRequestMarketNavigationData) {
            state.canRequestMarketNavigationData = canRequestMarketNavigationData;
        },
        SET_NAVIGATION_DATA(state, navigationData) {
            state.navigationData = navigationData;
        },
    },
});
function getUserRights() {
    const userRightElement = document.querySelector('[data-user-features]');
    if (userRightElement) {
        return JSON.parse(userRightElement.dataset.userFeatures);
    }

    return null;
}

function getOutlookPathAndCurrency() {
    if (marketData && Object.keys(marketData).length) {
        return {
            currency: marketData.currencies?.current?.isoCode,
            path: marketData.brand ? marketData.brand?.slug : marketData?.market?.slug,
        };
    }

    return null;
}
function isCustomView() {
    if (marketData?.comparedRegions && Object.keys(marketData?.comparedRegions).length > 0) {
        return VIEW_TYPE === CUSTOM_VIEW_TYPE;
    }
    if (!marketData?.comparedRegions?.length) {
        return marketData?.baseRegion?.regionType === 'customRegions';
    }

    return VIEW_TYPE === CUSTOM_VIEW_TYPE && !marketData?.baseRegion.slug;
}
function canViewGraphDetails(){
    if (USER_RIGHTS && CURRENT_OUTLOOK) {
        return USER_RIGHTS[`${ CURRENT_OUTLOOK }_CAN_VIEW_GRAPH_DETAILS`];
    }

    return null;
}
export { outlookStore };
