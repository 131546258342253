<template>
    <div class="animated fadeIn z-index-1 width100 text-center">
        <div class="xmoAiLoadingBanner">
            <div class="cssPreloader">
                <div class="cssPreloader__inner"></div>
            </div>
            <div class="xmoAiLoadingBanner__title">
                {{ trans('XMO_SEARCH_AI_RECOMMENDATIONS_TITLE') }}
                <span class="xmoSearchAiRecommendation__pill xmoSearchAiRecommendation__pill--large">
                    {{ trans('GLOBAL_BETA') }}
                </span>
            </div>
            <p class="xmoAiLoadingBanner__subtext">
                {{ trans('XMO_AI_SEARCH_GENERATING_CONTENT') }}
            </p>
        </div>
        <div class="xmoAiLoadingBannerFooter">
            <p>
                {{ trans('XMO_POWERED_BY') }}
                <span class="xmoAiLoadingBannerFooter__aiText">
                    {{ trans('OPEN_AI') }}
                </span>
            </p>
        </div>
    </div>
</template>

<script>
    import Translator from 'tools/trans';

    export default {
        methods: {
            trans: Translator.trans,
        },
    };
</script>
