import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';
import { AXIOS_RESPONSE_TYPES } from 'outlook/utils/doAxiosRequest';
import { getPath } from 'tools/getFromHtml';

const LOCALE_EN = 'en';

export const outlookSearchStore = new Vuex.Store({
    state: {
        searchInput: '',
        isLoadingAiResults: false,
        showAiResults: false,
        aiSearchResults: [],
        noAiResultsFound: false,
    },
    getters: {
        getIsLoadingAiResults: (state) => state.isLoadingAiResults,
    },
    mutations: {
        setIsLoadingAiResults(state, isLoadingAiResults) {
            state.isLoadingAiResults = isLoadingAiResults;
        },
        setShowAiResults(state, showAiResults) {
            state.showAiResults = showAiResults;
        },
        setSearchTerm(state, input) {
            state.searchInput = input;
        },
        setNoAiResultsFound(state, input) {
            state.noAiResultsFound = input;
        },
        requestAiSearchResults(state, query) {
            state.isLoadingAiResults = true;
            const path = getPath('outlookAiRecommendation');
            axios.post(path, { userInput: query, locale: LOCALE_EN })
                .then(({ status, data }) => {
                    const aiResults = JSON.parse(data);
                    if (status === AXIOS_RESPONSE_TYPES.OK && aiResults.length) {
                        state.aiSearchResults = aiResults;
                        state.showAiResults = true;
                    } else {
                        state.noAiResultsFound = true;
                    }
                    state.isLoadingAiResults = false;
                })
                .catch(error => {
                    console.error(error);
                    state.noAiResultsFound = true;
                    state.isLoadingAiResults = false;
                });
        },
    },
});
