var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "panel__opened" }, [
    _c(
      "div",
      { on: { mousemove: _vm.setCurrentHovered } },
      [
        _c(
          "ul",
          {
            staticClass:
              "outlookSearchResults scrollBox pos-static outlookSearchResults--small",
          },
          [
            _c("li", [
              _c(
                "h2",
                {
                  staticClass:
                    "marketingHubPage--quickStart__header text-color--dark-blue-gray",
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.searchResultsHeadline) +
                      "\n                    "
                  ),
                  _vm.showAiResults
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "xmoSearchAiRecommendation__pill xmoSearchAiRecommendation__pill--large",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.trans("GLOBAL_BETA")) +
                              "\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.matches, function (match, index) {
              return _c(
                "li",
                {
                  key: `${match.product}--${index}`,
                  ref: "results",
                  refInFor: true,
                  staticClass:
                    "marketingHubPageAutoComplete--resultsItem outlookMarketingHub__icons flex flex-center quickStart__item",
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                      )
                        return null
                      return _vm.changeProduct(match)
                    },
                    mousemove: function ($event) {
                      return _vm.setTabActive(match, _vm.$refs.results, $event)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "width50 marketingHubPage__results--products cursor-pointer",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "marketingLinks results--product focus-inset flex flex-center",
                          attrs: {
                            href: _vm.generateMarketLink(match.url, match.slug),
                            "data-gtm": `xmoMarketingPage__searchResult--${_vm.sanitizeDataGtm(
                              match
                            )}`,
                          },
                        },
                        [
                          _c("div", { staticClass: "margin-left-20" }, [
                            _c(
                              "svg",
                              {
                                class: _vm.generateIconClass(match),
                                attrs: {
                                  viewBox: "0 0 32 32",
                                  width: "32",
                                  height: "32",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "aria-hidden": "true",
                                },
                              },
                              [
                                _c("circle", {
                                  staticClass:
                                    "outlookClusterIcon__backgroundCircle",
                                  attrs: { cx: "16", cy: "16", r: "16" },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  staticClass: "outlookClusterIcon__iconPath",
                                  attrs: {
                                    d: _vm.getSvgIcon(match.product),
                                    fill: "none",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "flex flex--nowrap padding-right-20",
                            },
                            [
                              _c("div", [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "marketingHubPage__results--market margin-bottom-5 text-color--dark-blue-gray",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.generateSearchResultName(match)
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "resultsItem--product__small marketingHubPage__resultsHeader--topic",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.generateMarketName(match.product)
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.selectedProduct.uid === match.uid &&
                            !_vm.showAiResults,
                          expression:
                            "selectedProduct.uid === match.uid && !showAiResults",
                        },
                      ],
                      staticClass:
                        "width50 padding-right-20 outlookOverview scrollBox max-height-550 hideMobile",
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "hl-module text-color--primary-contrast margin-top-15 margin-bottom-10 margin-left-20",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.generateMarketName(
                                  _vm.selectedProduct.product
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h4",
                        { staticClass: "h3 margin-bottom-2 margin-left-20" },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.selectedProduct.market) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "margin-bottom-15 margin-left-20" },
                        [
                          _c("p", { staticClass: "margin-bottom-2" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.formatDescription(
                                    _vm.selectedProduct.description
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "margin-bottom-15 margin-left-20" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "linkArrowHover margin-top-20",
                              attrs: {
                                href: _vm.generateMarketLink(
                                  _vm.selectedProduct.url,
                                  _vm.selectedProduct.slug
                                ),
                                "data-gtm": `xmoMarketingPage__searchResultOverview--${_vm.sanitizeDataGtm(
                                  match
                                )}`,
                                tabindex: "-1",
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "stat__svgIcon",
                                  attrs: { "aria-hidden": "true" },
                                },
                                [
                                  _c("use", {
                                    attrs: { href: "#iconArrowRightLongBold" },
                                  }),
                                ]
                              ),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.trans("XMO_OUTLOOK_EXPLORE")) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.showAiResults
          ? _c("div", {
              attrs: {
                "data-fb-container": "xmoAiSearchResults__feedback",
                "data-fb-request": _vm.searchInput,
                "data-fb-response": JSON.stringify(_vm.aiSearchResults),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.showAiResults ? _c("AiRecommendationsPromo") : _vm._e(),
        _vm._v(" "),
        _vm.showAiResults
          ? _c("div", { staticClass: "xmoAiLoadingBannerFooter" }, [
              _c("p", [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.trans("XMO_POWERED_BY")) +
                    "\n                "
                ),
                _c(
                  "span",
                  { staticClass: "xmoAiLoadingBannerFooter__aiText" },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.trans("OPEN_AI")) +
                        "\n                "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }