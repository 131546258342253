/**
 * @param object {Object}
 * @param string {String}
 * @param fallback
 * @returns {*}
 */
function objectGet(object, string, fallback) {
    const properties = string.split('.');

    for (let i = 0, n = properties.length; i < n; ++i) {
        const property = properties[i];
        if (property in object) {
            object = object[property];
        } else {
            return fallback;
        }
    }

    return object;
}

export { objectGet };
