import Vue from 'vue';
import marketSearch from 'outlook/components/outlookMarketingSearch';
import homeXMOMarkets from 'outlook/components/homeXMOMarkets';
import { getFormFields } from 'tools/formFieldData';
import { getFromTemplate, getPath } from 'tools/getFromHtml';
import { outlookStore } from 'outlook/store/outlookStore';
import { ICONS, QUICKSTART_ITEMS } from 'outlook/constants/constants';

const locale = JSON.parse(getFromTemplate('var', 'locale'));
const formFields = getFormFields('[data-vue-home-XMO-search]', null, false);
const basePath = getPath('outlookHubPage');

const marketSearchElement = document.querySelector('[data-vue-home-XMO-search]');
const homeXMOMarketsElement = document.querySelector('[data-vue-home-XMO-markets]');

if (marketSearchElement) {
    new Vue({
        el: marketSearchElement,
        render(createElement) {
            return createElement(marketSearch, {
                store: outlookStore,
                props: {
                    locale,
                    basePath,
                    filterForm: formFields,
                    icons: ICONS,
                    formName: `${ formFields.q.name }--XMOHubpageSearch`,
                    popularMarkets: [],
                    quickStartItems: QUICKSTART_ITEMS,
                    showPopularMarkets: false,
                    isHomePageSearch: true,
                },
            });
        },
    });
}

if (homeXMOMarketsElement) {
    new Vue({
        el: homeXMOMarketsElement,
        render(createElement) {
            return createElement(homeXMOMarkets, {
                store: outlookStore,
                props: {
                    basePath,
                    outlookMarkets: QUICKSTART_ITEMS,
                    icons: ICONS,
                },
            });
        },
    });
}
