export { getFromTemplate as default, getTrans, getTracking, getPath, getFromTemplate };

/**
 * Init template storage object
 */
function initData() {
    if (window.stGlobals._template === undefined) {
        window.stGlobals._template = {};
    }
    window.stGlobals.getFromHtmlReady = true;
}

/**
 * Init group storage object
 * @param group
 */
function initGroupData(group) {
    if (window.stGlobals._template[group] === undefined) {
        window.stGlobals._template[group] = {};
    }
}

/**
 *
 * @param group
 * @param key
 * @returns {*}
 */
function getFromTemplate(group, key) {
    if (window.stGlobals.getFromHtmlReady !== true) {
        initData();
    }
    initGroupData(group);

    if (window.stGlobals._template[group][key]) {
        return window.stGlobals._template[group][key];
    } else {
        const selector = `[data-${ group }-name="${ key }"]`;
        const element = document.querySelector(selector);
        if (!element) {
            return null;
        }
        const content = convertStringBools(element.getAttribute(`data-${ group }`));
        window.stGlobals._template[group][key] = content;

        return content;
    }
}

/**
 * Get translation from HTML
 * @param key
 * @returns {*}
 */
function getTrans(key) {
    return getFromTemplate('trans', key);
}

/**
 * Get tracking data from HTML
 * @param key
 * @returns {*}
 */
function getTracking(key) {
    return getFromTemplate('tracking', key);
}

/**
 * Get Path data from HTML
 * @param key
 * @returns {*}
 */
function getPath(key) {
    return getFromTemplate('path', key);
}

function convertStringBools(possibleBool) {
    if (possibleBool === 'true' || possibleBool === 'false') {
        return possibleBool === 'true';
    }

    return possibleBool;
}
