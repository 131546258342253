<template>
    <div class="xmoSearchAiRecommendation">
        <div class="xmoSearchAiRecommendation__header">
            <span class="xmoSearchAiRecommendation__title">
                {{ trans('XMO_SEARCH_AI_RECOMMENDATIONS_TITLE') }}
            </span>
            <span class="xmoSearchAiRecommendation__pill">
                {{ trans('GLOBAL_BETA') }}
            </span>
        </div>
        <p class="xmoSearchAiRecommendation__text">
            {{ trans('XMO_SEARCH_AI_RECOMMENDATIONS_TEXT') }}
        </p>
        <button
            type="button"
            class="button button--primary"
            data-gtm="xmoNoSearchResults__showAiRecommendation"
            @click="requestAiResults">
            {{ trans('XMO_SEARCH_TRY_AI_RECOMMENDATIONS') }}
        </button>
    </div>
</template>

<script>
    import Translator from 'tools/trans';
    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState([
                'searchInput',
                'requestAiSearchResults',
            ]),
        },
        methods: {
            trans: Translator.trans,
            requestAiResults() {
                this.$store.commit('requestAiSearchResults', this.searchInput);
            },
        },
    };
</script>
