<template>
    <div
        v-show="fieldType !== 'hidden'"
        class="formInput__wrapper vueFloaty">
        <label
            v-if="separateLabel && fieldLabelVisible"
            class="hl-module"
            :for="fieldId">
            {{ fieldLabel }}
        </label>
        <input
            :id="fieldId"
            :ref="fieldId"
            v-model.lazy="fieldValue"
            :type="fieldType"
            :name="fieldName"
            :pattern="fieldPattern"
            :title="fieldTitle"
            :required="fieldRequired"
            :aria-invalid="`${ ariaInvalidStatus }`"
            :aria-describedBy="ariaInvalidStatus ? `${ fieldId }_describedBy` : null"
            :maxlength="fieldMaxlength"
            :minlength="fieldMinlength"
            :class="concattedClasses"
            :disabled="fieldDisabled"
            :readonly="fieldReadonly"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            @input="input"
            @focus="focusInput"
            @blur="blurInput"
            @keyup="keyup" />
        <label
            v-if="!separateLabel && fieldLabelVisible"
            :for="fieldId"
            :class="{
                'vueFloaty__label': true,
                'vueFloaty__label--active': floatActive,
                'text-color--primary': isActive,
            }">
            {{ fieldLabel }}
            <span
                v-if="fieldRequired === true"
                class="input__required">*
                <span class="visually-hidden">
                    {{ trans('LABEL_TEXT_SR_REQUIRED') }}
                </span>
            </span>
            <span
                v-if="ariaInvalidStatus === true && fieldAriaInvalid !== false"
                :id="`${ fieldId }_describedBy`"
                class="visually-hidden">
                {{ trans('FORM_REGISTER_FIELD_EMPTY') }}
            </span>
        </label>
        <slot></slot>
    </div>
</template>

<script>
    import formBaseProps from 'mixins/formBaseMixin';
    import formInputProps from 'mixins/formInputMixin';
    import Translator from 'tools/trans';

    export default {
        mixins: [formBaseProps, formInputProps],
        props: {
            trimOnBlur: { type: Boolean, default: false },
            separateLabel: { type: Boolean, default: false },
            errorClass: { type: Boolean, default: false },
            phoneNumber: { type: String, default: '' },
        },
        data() {
            return {
                isActive: false,
                showErrorAlert: false,
                LOGIN_FIELD_ID_USERNAME: ['loginStat_username_inline', 'loginStat_username'],
            };
        },
        computed: {
            fieldLabelVisible() {
                return this.fieldLabel && this.fieldLabel.length > 0;
            },
            floatActive() {
                return this.isActive || (this.fieldValue && this.fieldValue.length > 0);
            },
            concattedClasses() {
                let classes = '';
                if (!this.separateLabel && this.fieldLabelVisible) {
                    classes = 'vueFloaty__input';
                }
                if (this.showErrorAlert || this.errorClass) {
                    classes = 'vueFloaty__input red input--error';
                }
                if (this.phoneNumber && this.phoneNumber.length > 7) {
                    classes = 'vueFloaty__input input--success';
                }
                if (!this.phoneNumber && !this.errorClass && this.fieldValue && !this.separateLabel && this.fieldLabelVisible) {
                    classes = 'vueFloaty__input input--success';
                }

                return `${ classes } ${ this.fieldClasses }`;
            },
        },
        watch: {
            value(newValue) {
                if (this.fieldValue !== newValue) {
                    this.fieldValue = newValue;
                }
            },
            disabled(newDisabled) {
                if (this.fieldDisabled !== newDisabled) {
                    this.fieldDisabled = newDisabled;
                }
            },
            classes(newClasses) {
                if (this.fieldClasses !== newClasses) {
                    this.fieldClasses = newClasses;
                }
            },
        },
        methods: {
            trans: Translator.trans,
            getFieldID(id) {
                return this.LOGIN_FIELD_ID_USERNAME.includes(id);
            },
            focusInput(event) {
                this.$emit('focus', event);
                this.isActive = !this.fieldReadonly;
            },
            blurInput() {
                this.isActive = false;
                if (this.trimOnBlur) {
                    this.fieldValue = this.fieldValue.trim();
                }
                this.$emit('blur', this.fieldValue);
            },
            input(event) {
                this.fieldValue = event.currentTarget.value;
                this.$emit('input', this.fieldValue);
            },
            focusCursor() {
                this.$refs[this.fieldId].focus();
            },
            keyup(event) {
                this.$emit('keyup', event);
            },
        },
    };

</script>
