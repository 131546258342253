<template>
    <div
        class="xmoMarketingHubPage--markets flex flex--center"
        :class="{ 'flex--justifyContent-center': isHighlightsHeader && !isMobile }">
        <p
            v-if="popularMarketsTitle.length"
            class="margin-right-10">
            {{ popularMarketsTitle }}
        </p>
        <template v-if="hasPopularMarkets">
            <slot></slot>
        </template>
    </div>
</template>

<script>
    import { isMobile } from 'tools/isMobile';

    export default {
        props: {
            isHighlightsHeader: {
                type: Boolean,
                default: false,
            },
            hasPopularMarkets: {
                type: Boolean,
                required: true,
            },
            popularMarketsTitle: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                isMobile: isMobile(),
            };
        },
    };
</script>
