let hoverTimeout = null;
let hovered = null;
let currentBranch = null;

const NUMBER = 'number';
const MINIMUM_ANGLE_DEG = 45;
const TIMEOUT_MAX = 500;
const TIMEOUT_MIN = 50;
const TIMEOUT_DIVISOR = 10;
const axes = {
    x1: null,
    x2: null,
    y1: null,
    y2: null,
};
const config = {
    MOUSE_ANGLE: MINIMUM_ANGLE_DEG,
    HOVER_TIMEOUT: (TIMEOUT_MAX / TIMEOUT_DIVISOR),
    THREAD_TIMEOUT: (TIMEOUT_MIN / TIMEOUT_DIVISOR),
};

const threads = {
    threadOld: null,
    threadNew: null,
};

const setTabActive = {
    tabActive: null,
};

const getCurrentBranch = () => {
    return currentBranch;
};

const setCurrentBranch = (branch) => {
    currentBranch = branch;

    return currentBranch;
};

const setMouseAngle = (mouseAngle) => {
    config.MOUSE_ANGLE = mouseAngle;
};

const setHoverTimeout = (milliSeconds) => {
    config.HOVER_TIMEOUT = milliSeconds;
};

const isNumber = (arg) => {
    return typeof arg === NUMBER;
};

const getMouseAngle = (x1, y1, x2, y2) => {
    const distY = Math.abs(y2 - y1);
    const distX = Math.abs(x2 - x1);
    const dist = Math.sqrt((distY * distY) + (distX * distX));
    const val = distY/dist;

    return Math.asin(val)*(180/Math.PI);
};

const changeActiveTab = (tabId, refs, event, mouseAngle) => {
    axes.x1 = axes.x2;
    axes.y1 = axes.y2;
    axes.x2 = event.pageX;
    axes.y2 = event.pageY;
    clearTimeout(threads.threadNew);
    if (getMouseAngle(axes.x1, axes.y1, axes.x2, axes.y2) > mouseAngle) {
        setCurrentBranch(tabId);
    } else {
        threads.threadNew = setTimeout(() => {
            if (refs.includes(hovered)) {
                setCurrentBranch(tabId);
            }
        }, TIMEOUT_MAX);
    }

    setTabActive.tabActive = false;
};

/**
 * @param tabId
 * @param refs
 * @param event
 * @param mouseAngle type: number (defaults value = 45°)
 * @returns currentBranch
 */
const activateTab = (tabId, refs, event, mouseAngle = config.MOUSE_ANGLE) => {
    if (isNumber(mouseAngle)) {
        setMouseAngle(mouseAngle);
    }
    if (!setTabActive.tabActive) {
        axes.x1 = event.pageX;
        axes.y1 = event.pageY;
        setTabActive.tabActive = true;
    }
    clearTimeout(threads.threadOld);
    threads.threadOld = setTimeout(() => changeActiveTab(tabId, refs, event, mouseAngle), config.THREAD_TIMEOUT);

    return getCurrentBranch();
};

/**
 * Set hovered element node as current target after a specific interval;
 * @param event
 * @param timeoutMilliSeconds: type number(defaults to 50 milli seconds).
 */
const setCurrentHovered = (event, timeoutMilliSeconds = config.HOVER_TIMEOUT) => {
    clearTimeout(hoverTimeout);
    if (isNumber(timeoutMilliSeconds)) {
        setHoverTimeout(timeoutMilliSeconds);
    }
    hoverTimeout = setTimeout(() => {
        hovered = event.target;
    }, timeoutMilliSeconds);
};

export { activateTab, setCurrentHovered };
