var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "margin-top-0 flex margin-bottom-s-60" },
    [
      _c(
        "PopularMarkets",
        {
          attrs: {
            "is-mobile": _vm.isMobile,
            "has-popular-markets": _vm.hasPopularMarkets,
          },
        },
        _vm._l(_vm.getMarketNames, function (xmoMarket, index) {
          return _c(
            "a",
            {
              key: `${xmoMarket.name}--${index}`,
              staticClass:
                "homeXmoMarketing__badges text-color--dark-blue-gray margin-right-10",
              attrs: {
                "data-gtm": `xmoMarketingPage__popularMarkets--${xmoMarket.name}`,
                href: `${xmoMarket.uri}/${xmoMarket.path}`,
              },
              on: {
                mouseleave: function ($event) {
                  return _vm.disableTooltip()
                },
                mouseover: function ($event) {
                  return _vm.setTooltipContent(xmoMarket)
                },
              },
            },
            [
              !_vm.showTooltip
                ? _c("span", [_vm._v(" " + _vm._s(xmoMarket.name) + " ")])
                : _vm._e(),
              _vm._v(" "),
              _vm.showTooltip
                ? _c(
                    "Tooltip",
                    {
                      attrs: {
                        "hide-on-mobile": true,
                        settings: { position: "top", width: 302 },
                        content: _vm.tooltipContent,
                      },
                    },
                    [_c("span", [_vm._v(" " + _vm._s(xmoMarket.name) + " ")])]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }