export { getFormFields };

function getFormFields(container = null, node = null, fullNameKey = true) {
    const selector = container ? `${ container } [data-field]` : '[data-field]';
    const wrapperNode = node ? node : document;
    const fields = Array.prototype.slice.call(wrapperNode.querySelectorAll(selector));

    return fields.reduce((form, field) => {
        if (field.dataset.attributes) {
            const fieldData = JSON.parse(field.dataset.attributes);
            const fieldKey = fullNameKey ? fieldData.id : fieldData.name;
            form[fieldKey] = JSON.parse(field.dataset.attributes);
        }

        return form;
    }, {});
}
