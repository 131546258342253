import { objectGet } from 'tools/objectGet';

export default {
    props: {
        field: { type: Object, default: () => ({}) },
        id: { type: String, default: null },
        name: { type: String, default: null },
        classes: { type: String, default: null },
        label: { type: String|Boolean, default: null },
        required: { type: Boolean, default: null },
        disabled: { type: Boolean, default: null },
        ariaInvalid: { type: String|Boolean, default: false },
    },
    data() {
        return {
            fieldId: this.getFieldValue('id', 'id', ''),
            fieldName: this.getFieldValue('name', 'full_name', ''),
            fieldClasses: this.getFieldValue('classes', 'attr.class', ''),
            fieldLabel: this.getFieldValue('label', 'label', ''),
            fieldRequired: this.getFieldValue('required', 'required', false),
            fieldDisabled: this.getFieldValue('disabled', 'disabled', false),
            fieldAriaInvalid: false,
        };
    },
    computed: {
        ariaInvalidStatus() {
            return this.ariaInvalid || this.fieldAriaInvalid;
        },
    },
    methods: {
        getFieldValue(prop, formAttribute, fallback) {
            return this[prop] !== null ? this[prop] : objectGet(this.field, formAttribute, fallback);
        },
        updateValidaty() {
            const formInputRef = this.$refs[this.fieldId];
            if (formInputRef) {
                this.fieldAriaInvalid = !formInputRef.checkValidity();
            }
        },
        updateAriaValidity(children) {
            children.forEach(element => {
                if (element.$children) {
                    this.updateAriaValidity(element.$children);
                }
                if (Object.prototype.hasOwnProperty.call(element, 'updateValidaty')) {
                    element.updateValidaty();
                }
            });
        },
        checkValidityHTML5() {
            this.updateAriaValidity(this.$children);
        },
    },
    watch: {
        id(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldId = newValue;
            }
        },
        name(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldName = newValue;
            }
        },
        classes(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldClasses = newValue;
            }
        },
        label(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldLabel = newValue;
            }
        },
        required(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldRequired = newValue;
            }
        },
        disabled(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.fieldDisabled = newValue;
            }
        },
    },
};
