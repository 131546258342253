<template>
    <div
        v-click-outside="hideDropdown"
        class="responsiveSection__grid xmoSearchWrapper">
        <div class="marketingHubPageSearch__searchWrapper margin-bottom-s-0">
            <form
                :name="formName"
                autocomplete="off"
                @submit.prevent="makeSearchRequest(debouncedSearchTerm)">
                <FormInput
                    ref="input"
                    v-model="searchTerm"
                    classes="marketingHubPageSearch__searchInput"
                    :name="formName"
                    :field="filterFormData.q"
                    :placeholder="getPlaceholderText"
                    :disabled="isLoadingAiResults"
                    @focus="initQuickStart">
                    <!-- Mag-Glass -->
                    <svg
                        aria-hidden="true"
                        class="marketingHubPageSearch__searchSvg"
                        :class="{ 'marketingHubPageSearch__searchSvg--disabled': isLoadingAiResults }">
                        <use xlink:href="#iconMagGlassBold" />
                    </svg>
                </FormInput>
            </form>
        </div>
        <template>
            <div
                v-if="views.quickStart && isMobile"
                class="panelCard marketingHubPage__quickStart--mobile">
                <slot name="quickstart-header"></slot>
            </div>
            <div class="marketingHubPage__autoCompleteList panelCard">
                <AiLoadingBanner v-if="isLoadingAiResults" />
                <template v-else-if="isHomePageSearch">
                    <!-- Quick Start -->
                    <QuickStart
                        v-if="views.quickStart && quickStartItems.length"
                        :quick-start-items="quickStartItems"
                        :base-path="basePath"
                        :icons="icons" />
                    <!-- Ajax Results -->
                    <SearchResults
                        v-if="showAjaxResults"
                        :icons="icons"
                        :base-path="basePath"
                        :matched-search-results="searchResults" />
                    <!-- No Results -->
                    <template v-if="showNoResultsAvailable">
                        <NoResultsAiVersion v-if="!showAiResults" />
                        <NoResults v-else />
                    </template>
                </template>
                <template v-else>
                    <!-- Quick Start -->
                    <slot
                        v-if="views.quickStart"
                        name="quickstart">
                    </slot>
                    <!-- Ajax Results -->
                    <slot
                        v-if="showAjaxResults"
                        name="results"
                        :matched-search-results="searchResults">
                    </slot>
                    <!-- No Results -->
                    <template v-if="showNoResultsAvailable && !views.quickStart">
                        <NoResultsAiVersion v-if="!showAiResults" />
                        <NoResults v-else />
                    </template>
                </template>
            </div>
        </template>
        <PopularMarkets
            v-if="showPopularMarkets"
            :has-popular-markets="hasPopularMarkets"
            :popular-markets-title="trans('OUTLOOK_POPULAR_MARKETS')"
            :is-highlights-header="isHighlightsHeader">
            <a
                v-for="(popularMarket, index) in popularMarkets"
                :key="`${ popularMarket.name }--${ index }`"
                :data-gtm="`xmoMarketingPage__popularMarkets--${ popularMarket.name }`"
                :href="`${ basePath }${ getPopularMarketLink(popularMarket) }`"
                class="xmoMarketingHubPage__markets--badges text-color--dark-blue-gray margin-right-10">
                {{ popularMarket.name }}
            </a>
        </PopularMarkets>
    </div>
</template>
<script>
    import axios from 'axios';
    import Translator from 'tools/trans';
    import clickOutside from 'mixins/clickOutsideMixin';
    import FormInput from 'components/form/formInput';
    import QuickStart from 'outlook/components/hubPage/quickStart';
    import SearchResults from 'outlook/components/hubPage/searchResults';
    import PopularMarkets from 'outlook/components/hubPage/popularMarkets';
    import AiLoadingBanner from 'outlook/components/hubPage/aiLoadingBanner';
    import { getFromTemplate, getPath } from 'tools/getFromHtml';
    import { debounce } from 'tools/debounce';
    import { isMobile } from 'tools/isMobile';
    import { addMessage } from 'tools/notifications';
    import { dataLayer } from 'globals/datalayerPusher';
    import { outlookSearchStore } from 'outlook/store/outlookSearchStore';
    import { mapState } from 'vuex';

    const SEARCH_TERM_DEBOUNCE_MS = 300;
    const QUALIFIED_SEARCH_TERM_LENGTH = 2;
    const XMLHTTPREQUEST_HEADER = { 'X-Requested-With': 'XMLHttpRequest' };
    const ESCAPE_KEY = 'Escape';
    const ESCAPE_KEY_IE = 'Esc';
    const RESULT_TYPE_MARKET = 'market';
    const DE = 'de';
    const EN = 'en';

    export default {
        components: {
            FormInput,
            QuickStart,
            SearchResults,
            PopularMarkets,
            NoResults: () => import('outlook/components/hubPage/noResults'),
            NoResultsAiVersion: () => import('outlook/components/hubPage/noResultsAiVersion'),
            AiLoadingBanner,
        },
        store: outlookSearchStore,
        mixins: [clickOutside],
        props: {
            locale: {
                type: String,
                required: true,
            },
            filterForm: {
                type: Object,
                required: true,
            },
            icons: {
                type: Object,
                default: () => undefined,
            },
            popularMarkets: {
                type: Array,
                default: () => [],
            },
            quickStartItems: {
                type: Array,
                default: () => [],
            },
            isHomePageSearch: {
                type: Boolean,
                default: false,
            },
            basePath: {
                type: String,
                default: '',
            },
            formName: { type: String, required: true },
            outlookMarkets: {
                type: Array,
                default: () => [],
            },
            showPopularMarkets: {
                type: Boolean,
                required: true,
            },
            placeholder: {
                type: String,
                default: '',
            },
            requestPath: {
                type: String,
                default: '',
            },
            isHighlightsHeader: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                views: {
                    ajaxResults: false,
                    quickStart: false,
                },
                noResultsAvailable: false,
                showNoResultsAvailable: false,
                filterFormData: this.filterForm,
                debouncedSearchTerm: '',
                escapeKeys: [
                    ESCAPE_KEY,
                    ESCAPE_KEY_IE,
                ],
                searchResultsAjax: [],
                matchedSearchResults: [],
                isMobile: isMobile(),
                currentOutlook: getFromTemplate('var', 'outlookName'),
            };
        },
        computed: {
            ...mapState([
                'searchInput',
                'showAiResults',
                'aiSearchResults',
                'isLoadingAiResults',
            ]),
            searchTerm: {
                get() {
                    return this.searchInput;
                },
                set(input) {
                    return this.$store.commit('setSearchTerm', input);
                },
            },
            getAjaxRequestPath() {
                if (this.requestPath.length > 0) {
                    return this.requestPath;
                }

                return 'outlookMarketingSearchAjax';
            },
            getPlaceholderText() {
                if (this.placeholder.length > 0) {
                    return this.placeholder;
                }

                return Translator.trans('OUTLOOK_SEARCH_PLACEHOLDER');
            },
            showAjaxResults() {
                return (this.views.ajaxResults || this.showAiResults) && !this.views.quickStart;
            },
            hasPopularMarkets() {
                // Returning true until backend API is provided.
                return true;
            },
            isQualifiedSearchLength() {
                return this.debouncedSearchTerm.length > QUALIFIED_SEARCH_TERM_LENGTH;
            },
            sanitizedSearchTerm() {
                return this.debouncedSearchTerm.trim();
            },
            pathLocale() {
                return this.locale === DE ? DE : EN;
            },
            searchResults() {
                return this.showAiResults ? this.aiSearchResults : this.matchedSearchResults;
            },
        },
        watch: {
            searchTerm:
                debounce(function(newVal) {
                    const trimmedNewVal = newVal.trim();

                    if (trimmedNewVal === this.debouncedSearchTerm) {
                        return;
                    }
                    this.$store.commit('setShowAiResults', false);
                    this.$store.commit('setNoAiResultsFound', false);
                    this.debouncedSearchTerm = newVal;
                    if (!this.searchTerm.length) {
                        this.noResultsAvailable = false;
                        this.views.ajaxResults = false;
                        this.views.quickStart = true;
                    }
                    if (this.views.quickStart && !this.searchTerm.length) {
                        this.showNoResultsAvailable = false;
                        this.views.quickStart = true;
                    }
                    this.makeSearchRequest(this.debouncedSearchTerm);
                }, SEARCH_TERM_DEBOUNCE_MS),
            searchResultsAjax(){
                if (!this.debouncedSearchTerm.length > 0) {
                    this.views.quickStart = true;

                    return;
                }
                if (this.isArray(this.searchResultsAjax) && this.searchResultsAjax.length){
                    this.matchedSearchResults = this.filterResults(this.searchResultsAjax, RESULT_TYPE_MARKET);
                    this.views.quickStart = false;
                    this.views.ajaxResults = true;
                } else {
                    this.views.quickStart = false;
                }
            },
        },
        methods: {
            trans: Translator.trans,
            getPath,
            isArray(data) {
                return Array.isArray(data);
            },
            doAjaxRequest(AjaxEndpoint, headers = {}) {
                return axios.get(AjaxEndpoint, {
                    headers: { ...headers },
                })
                    .then(({ data }) => data)
                    .catch((error) => {
                        addMessage({ text: Translator.trans('OUTLOOK_REQUEST_ERROR'), icon: 'error' });
                        console.error(error);
                    });
            },
            filterResults(array, filterParam) {
                return array.filter((item) => {
                    if (item.type && item.type === filterParam) {
                        return item;
                    }

                    return false;
                });
            },
            makeSearchRequest(searchTerm) {
                if (this.sanitizedSearchTerm.length && this.isQualifiedSearchLength) {
                    const endpoint = `${ this.getPath(this.getAjaxRequestPath) }?q=${ searchTerm }&outlookName=${ this.currentOutlook }`;

                    return this.doAjaxRequest(endpoint, XMLHTTPREQUEST_HEADER)
                        .then((data) => {
                            const { results: { matches } } = JSON.parse(data);
                            dataLayer.pushOutlookSearch({ ...JSON.parse(data), searchTerm });
                            if (!matches.length && this.debouncedSearchTerm.length > 0) {
                                this.noResultsAvailable = true;
                                this.showNoResultsAvailable = true;
                                this.views.quickStart = false;
                                this.views.ajaxResults = false;
                            }
                            if (this.isArray(matches) && matches.length) {
                                this.noResultsAvailable = false;
                                this.showNoResultsAvailable = false;
                                this.searchResultsAjax = matches;
                            }
                        });
                }
            },
            initQuickStart() {
                if (this.noResultsAvailable) {
                    this.showNoResultsAvailable = true;
                } else if (this.searchTerm.length) {
                    this.views.ajaxResults = true;
                } else {
                    this.views.quickStart = true;
                }
            },
            hideDropdown() {
                this.showNoResultsAvailable = false;
                this.views.ajaxResults = false;
                this.views.quickStart = false;
                this.$store.commit('setShowAiResults', false);
            },
            getPopularMarketLink(market) {
                if (!market[`${ this.pathLocale }Path`]) {
                    return market.slug;
                }

                return market[`${ this.pathLocale }Path`];
            },
        },
        created() {
            window.addEventListener('keyup', (event) => {
                if (this.escapeKeys.includes(event.key)) {
                    this.hideDropdown();
                }
            });
        },
    };
</script>
