var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g(
      {
        ref: "trigger",
        staticClass: "vueTooltip__trigger",
        class: { "vueTooltip__trigger--clickable": _vm.config.openOnClick },
        attrs: { "data-gtm": _vm.gtm },
        on: {
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar",
                ])
              )
                return null
              return _vm.onSpace($event)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              )
                return null
              return _vm.onTab($event)
            },
          ],
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            $event.stopPropagation()
            return _vm.hideTooltip()
          },
        },
      },
      !_vm.tooltipDisabled
        ? {
            focusin: () =>
              !_vm.config.openOnClick ? _vm.showTooltip(true) : null,
            mouseenter: () =>
              !_vm.config.openOnClick ? _vm.showTooltipDelayed() : null,
            mouseleave: () =>
              !_vm.config.openOnClick ? _vm.delayHideTooltip() : null,
            click: () => (_vm.config.openOnClick ? _vm.onClick() : null),
          }
        : {}
    ),
    [
      _vm._t("default", null, { show: _vm.showTooltip, hide: _vm.hideTooltip }),
      _vm._v(" "),
      !_vm.tooltipDisabled
        ? _c(
            "div",
            { ref: "tooltipWrapper", staticClass: "vueTooltip__wrap" },
            [
              _c(
                "div",
                {
                  ref: "tooltip",
                  staticClass: "vueTooltip animated animation--duration-500",
                  class: _vm.tooltipClasses,
                  style: _vm.tooltipStyles,
                  attrs: { role: "tooltip", hidden: _vm.tooltipHidden },
                  on: {
                    mouseenter: function ($event) {
                      !_vm.config.openOnClick
                        ? _vm.blockFadeOut()
                        : _vm.setPreventHideOnBlur(true)
                    },
                    mouseleave: function ($event) {
                      !_vm.config.openOnClick
                        ? _vm.fadeAndHide()
                        : _vm.setPreventHideOnBlur(false)
                    },
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        )
                          return null
                        return _vm.handleTabInsideTooltip($event)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        $event.stopPropagation()
                        return _vm.fadeAndHide(true)
                      },
                    ],
                  },
                },
                [
                  _vm._t("content", function () {
                    return [
                      _vm.heading
                        ? _c("p", {
                            staticClass: "vueTooltip__heading",
                            domProps: { innerHTML: _vm._s(_vm.heading) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "vueTooltip__content",
                        domProps: { innerHTML: _vm._s(_vm.content) },
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }