<template>
    <div class="margin-top-0 flex margin-bottom-s-60">
        <PopularMarkets
            :is-mobile="isMobile"
            :has-popular-markets="hasPopularMarkets">
            <a
                v-for="(xmoMarket, index) in getMarketNames"
                :key="`${ xmoMarket.name }--${ index }`"
                :data-gtm="`xmoMarketingPage__popularMarkets--${ xmoMarket.name }`"
                :href="`${ xmoMarket.uri }/${ xmoMarket.path }`"
                class="homeXmoMarketing__badges text-color--dark-blue-gray margin-right-10"
                @mouseleave="disableTooltip()"
                @mouseover="setTooltipContent(xmoMarket)">
                <span v-if="!showTooltip"> {{ xmoMarket.name }} </span>
                <Tooltip
                    v-if="showTooltip"
                    :hide-on-mobile="true"
                    :settings="{ position: 'top', width: 302 }"
                    :content="tooltipContent">
                    <span> {{ xmoMarket.name }} </span>
                </Tooltip>
            </a>
        </PopularMarkets>
    </div>
</template>

<script>
    import PopularMarkets from 'outlook/components/hubPage/popularMarkets';
    import Tooltip from 'components/tooltip';
    import { isMobile } from 'tools/isMobile';
    import { generateMarketSubTitle } from 'outlook/utils/generateMarketName';

    const DE = 'de';
    const LOCALE = window.stGlobals.locale;
    const uri = LOCALE === DE ? 'https://de.statista.com/outlook' : 'https://www.statista.com/outlook';

    export default {
        components: {
            PopularMarkets,
            Tooltip,
        },
        props: {
            outlookMarkets: {
                type: Array,
                required: true,
            },
            basePath: {
                type: String,
                required: true,
            },
            icons: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isMobile: isMobile(),
                tooltipContent: '',
                svgBaseClass: 'outlookClusterIcon outlookClusterIcon--',
                showTooltip: false,
            };
        },
        computed: {
            hasPopularMarkets() {
                // Returning true until backend API is provided.
                return true;
            },
            /* eslint-disable vue/no-mutating-props, vue/no-side-effects-in-computed-properties */
            getMarketNames() {
                return this.outlookMarkets
                    .sort((current, comparator) => current.shortName.localeCompare(comparator.shortName))
                    .map((market) => ({ name: market.shortName, uri, path: market.path, shortTitle: market.shortTitle }));
            },
            /* eslint-enable */
        },
        methods: {
            buildSvg(iconClass, iconPath) {
                return `<span class="outlookMarketingHub__icons margin-right-10">
                            <svg
                            viewBox="0 0 32 32"
                            class="${ iconClass }"
                            width="32"
                            height="32"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true">
                            <circle
                                class="outlookClusterIcon__backgroundCircle"
                                cx="16"
                                cy="16"
                                r="16" />
                            <path
                                class="outlookClusterIcon__iconPath"
                                d="${ iconPath }"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </span>`;
            },
            buildTooltipContent(marketSvg, shortTitle) {
                const marketSubTitle = generateMarketSubTitle(shortTitle);

                return `<div class="flex flex--center flex--nowrap quickStart__item margin-top-2">
                            <span>${ marketSvg }</span>
                            <p class="margin-bottom-5">${ marketSubTitle }</p>
                        </div>`;
            },
            disableTooltip() {
                this.showTooltip = false;
            },
            setTooltipContent({ shortTitle }) {
                this.showTooltip = true;
                const iconPath = this.icons[shortTitle].path;
                const iconClass = `${ this.svgBaseClass }${ shortTitle }`;
                const marketSvg = this.buildSvg(iconClass, iconPath);
                this.tooltipContent = this.buildTooltipContent(marketSvg, shortTitle);
            },
        },
    };
</script>
