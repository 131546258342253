import axios, { AxiosResponse, AxiosError } from 'axios';
import Translator from 'tools/trans';
import { addMessage } from 'tools/notifications';

const PARAMS = 'params';
const CONFIG = 'config';

const AXIOS_REQUEST_TYPES = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
};

const AXIOS_RESPONSE_TYPES = {
    OK: 200,
    NOT_FOUND: 404,
    GATEWAY_TIMEOUT: 504,
};

const doAjaxRequest = (requestType, ajaxEndpoint, params) => {
    const axiosConfig = { method: requestType, url: ajaxEndpoint };
    axiosConfig[PARAMS] = params;
    axiosConfig[CONFIG] = { headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' } };

    return axios(axiosConfig)
        .catch((error) => {
            addMessage({ text: Translator.trans('OUTLOOK_REQUEST_ERROR'), icon: 'error' });
            throw error;
        });
};

export {
    doAjaxRequest,
    AXIOS_REQUEST_TYPES,
    AXIOS_RESPONSE_TYPES,
    AxiosResponse,
    AxiosError,
};
