function elementStopPropagation(event) {
    event.stopPropagation();
}

export default {
    directives: {
        'click-outside': {
            bind: (el, binding, vnode) => {
                setTimeout(() => {
                    el.addEventListener('click', elementStopPropagation);
                    document.body.addEventListener('click', vnode.context[binding.expression]);
                }, 0);
            },
            unbind: (el, binding, vnode) => {
                setTimeout(() => {
                    el.removeEventListener('click', elementStopPropagation);
                    document.body.removeEventListener('click', vnode.context[binding.expression]);
                }, 0);
            },
        },
    },
};
