var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideDropdown,
          expression: "hideDropdown",
        },
      ],
      staticClass: "responsiveSection__grid xmoSearchWrapper",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "marketingHubPageSearch__searchWrapper margin-bottom-s-0",
        },
        [
          _c(
            "form",
            {
              attrs: { name: _vm.formName, autocomplete: "off" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.makeSearchRequest(_vm.debouncedSearchTerm)
                },
              },
            },
            [
              _c(
                "FormInput",
                {
                  ref: "input",
                  attrs: {
                    classes: "marketingHubPageSearch__searchInput",
                    name: _vm.formName,
                    field: _vm.filterFormData.q,
                    placeholder: _vm.getPlaceholderText,
                    disabled: _vm.isLoadingAiResults,
                  },
                  on: { focus: _vm.initQuickStart },
                  model: {
                    value: _vm.searchTerm,
                    callback: function ($$v) {
                      _vm.searchTerm = $$v
                    },
                    expression: "searchTerm",
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "marketingHubPageSearch__searchSvg",
                      class: {
                        "marketingHubPageSearch__searchSvg--disabled":
                          _vm.isLoadingAiResults,
                      },
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#iconMagGlassBold" },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      [
        _vm.views.quickStart && _vm.isMobile
          ? _c(
              "div",
              { staticClass: "panelCard marketingHubPage__quickStart--mobile" },
              [_vm._t("quickstart-header")],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "marketingHubPage__autoCompleteList panelCard" },
          [
            _vm.isLoadingAiResults
              ? _c("AiLoadingBanner")
              : _vm.isHomePageSearch
              ? [
                  _vm.views.quickStart && _vm.quickStartItems.length
                    ? _c("QuickStart", {
                        attrs: {
                          "quick-start-items": _vm.quickStartItems,
                          "base-path": _vm.basePath,
                          icons: _vm.icons,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showAjaxResults
                    ? _c("SearchResults", {
                        attrs: {
                          icons: _vm.icons,
                          "base-path": _vm.basePath,
                          "matched-search-results": _vm.searchResults,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showNoResultsAvailable
                    ? [
                        !_vm.showAiResults
                          ? _c("NoResultsAiVersion")
                          : _c("NoResults"),
                      ]
                    : _vm._e(),
                ]
              : [
                  _vm.views.quickStart ? _vm._t("quickstart") : _vm._e(),
                  _vm._v(" "),
                  _vm.showAjaxResults
                    ? _vm._t("results", null, {
                        matchedSearchResults: _vm.searchResults,
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showNoResultsAvailable && !_vm.views.quickStart
                    ? [
                        !_vm.showAiResults
                          ? _c("NoResultsAiVersion")
                          : _c("NoResults"),
                      ]
                    : _vm._e(),
                ],
          ],
          2
        ),
      ],
      _vm._v(" "),
      _vm.showPopularMarkets
        ? _c(
            "PopularMarkets",
            {
              attrs: {
                "has-popular-markets": _vm.hasPopularMarkets,
                "popular-markets-title": _vm.trans("OUTLOOK_POPULAR_MARKETS"),
                "is-highlights-header": _vm.isHighlightsHeader,
              },
            },
            _vm._l(_vm.popularMarkets, function (popularMarket, index) {
              return _c(
                "a",
                {
                  key: `${popularMarket.name}--${index}`,
                  staticClass:
                    "xmoMarketingHubPage__markets--badges text-color--dark-blue-gray margin-right-10",
                  attrs: {
                    "data-gtm": `xmoMarketingPage__popularMarkets--${popularMarket.name}`,
                    href: `${_vm.basePath}${_vm.getPopularMarketLink(
                      popularMarket
                    )}`,
                  },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(popularMarket.name) + "\n        "
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }