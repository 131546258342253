var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn z-index-1 width100 text-center" },
    [
      _c("div", { staticClass: "xmoAiLoadingBanner" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "xmoAiLoadingBanner__title" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.trans("XMO_SEARCH_AI_RECOMMENDATIONS_TITLE")) +
              "\n            "
          ),
          _c(
            "span",
            {
              staticClass:
                "xmoSearchAiRecommendation__pill xmoSearchAiRecommendation__pill--large",
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("GLOBAL_BETA")) +
                  "\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "xmoAiLoadingBanner__subtext" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.trans("XMO_AI_SEARCH_GENERATING_CONTENT")) +
              "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "xmoAiLoadingBannerFooter" }, [
        _c("p", [
          _vm._v(
            "\n            " +
              _vm._s(_vm.trans("XMO_POWERED_BY")) +
              "\n            "
          ),
          _c("span", { staticClass: "xmoAiLoadingBannerFooter__aiText" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.trans("OPEN_AI")) +
                "\n            "
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cssPreloader" }, [
      _c("div", { staticClass: "cssPreloader__inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }