var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "xmoSearchAiRecommendation" }, [
    _c("div", { staticClass: "xmoSearchAiRecommendation__header" }, [
      _c("span", { staticClass: "xmoSearchAiRecommendation__title" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.trans("XMO_SEARCH_AI_RECOMMENDATIONS_TITLE")) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "xmoSearchAiRecommendation__pill" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.trans("GLOBAL_BETA")) + "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "xmoSearchAiRecommendation__text" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.trans("XMO_SEARCH_AI_RECOMMENDATIONS_TEXT")) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button button--primary",
        attrs: {
          type: "button",
          "data-gtm": "xmoNoSearchResults__showAiRecommendation",
        },
        on: { click: _vm.requestAiResults },
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm.trans("XMO_SEARCH_TRY_AI_RECOMMENDATIONS")) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }