function debounce(fn, delay) {
    let timeoutID = null;

    return function() {
        clearTimeout(timeoutID);
        const args = arguments;
        const that = this; // eslint-disable-line @typescript-eslint/no-this-alias
        timeoutID = setTimeout(function() {
            fn.apply(that, args);
        }, delay);
    };
}

export { debounce };
