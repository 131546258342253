<template>
    <div>
        <div class="marketingHubPage__quickStart outlookMarketingHub__icons panel__opened">
            <h2 class="marketingHubPage--quickStart__header text-color--dark-blue-gray hideMobile">
                {{ trans('XMO_MARKET_OUTLOOKS_QUICKSTART') }}
            </h2>
            <a
                v-for="(item, index) in quickStartItems"
                :key="`${ item.shortTitle }--${ index }`"
                :href="`${ basePath }${ item.path }`"
                :data-gtm="`xmoMarketingPage__quickstart--${ item.shortTitle }`"
                class="flex flex--center flex--nowrap quickStart__item"
                @mousemove="setShortTitle(item.shortTitle)">
                <div class="quickStart__svg">
                    <svg
                        viewBox="0 0 32 32"
                        :class="generateIconClass(item.shortTitle)"
                        width="32"
                        height="32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true">
                        <circle
                            class="outlookClusterIcon__backgroundCircle"
                            cx="16"
                            cy="16"
                            r="16" />
                        <path
                            class="outlookClusterIcon__iconPath"
                            :d="icons[item.shortTitle].path"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="margin-bottom-10 margin-bottom-s-5 padding-right-20">
                    <h3 class="text-color--dark-blue-gray margin-top-10 margin-bottom-5 marketingHubPage__resultsHeader">
                        {{ trans(item.name) }}
                    </h3>
                    <small>
                        {{ item.teaser }}
                    </small>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
    import Translator from 'tools/trans';

    export default {
        props: {
            quickStartItems: { type: Array, required: true },
            icons: { type: Object, required: true },
            basePath: { type: String, required: true },
        },
        data() {
            return {
                selectedItem: '',
                svgBaseClass: 'outlookClusterIcon outlookClusterIcon--',
            };
        },
        methods: {
            trans: Translator.trans,
            generateIconClass(shortTitle) {
                if (shortTitle === this.selectedItem) {
                    return `${ this.svgBaseClass }${ shortTitle }`;
                }

                return '';
            },
            setShortTitle(item) {
                this.selectedItem = item;
            },
        },

    };
</script>
